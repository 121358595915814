<template>
    <AppLayout>
        <div class="mx-auto 2xl:max-w-9xl px-4 detail-page" v-if="profileSelected">
            <div class="hero-profile text-center mt-16 max-w-[1500px] mx-auto pt-20">
                <div class="bg-white rounded-3xl mt-10">
                    <div class="w-full flex big-img-profile">
                        <div class="img-team-container mx-auto">
                            <img :src="profileSelected.profile_picture_url" alt=""
                                v-if="profileSelected.profile_picture_url"
                                class="img-team  md:!w-64 md:!h-64 sm:!w-48 sm:!h-48">
                            <UserCircleIcon class="stroke-white stroke-1 img-team  md:!w-24 md:!h-24 sm:!w-48 sm:!h-48"
                                aria-hidden="true" v-else />
                        </div>
                    </div>
                    <h1 class="text-center text-4xl md:text-5xl mx-auto mt-10 !mb-6" v-if="profileSelected.user">{{
            profileSelected.user.name }}
                    </h1>
                    <p class="uppercase tracking-widest text-lg text-indigo-900" v-if="profileSelected.role">{{
            profileSelected.role.name }}</p>
                    <span v-for="relatedStructures in profileSelected.structures" :key="relatedStructures.id"
                        class="mt-4 inline-flex items-center gap-x-1.5 rounded-full px-4 py-1 text-md font-normal text-gray-500 ring-1 ring-inset ring-gray-200">
                        <svg class="h-1.5 w-1.5 fill-indigo-500" viewBox="0 0 6 6" aria-hidden="true">
                            <circle cx="3" cy="3" r="3" />
                        </svg>
                        {{ relatedStructures.name }}
                    </span>
                </div>

            </div>
            <div class="container-page pt-6 bg-white rounded-xl -mt-10 !max-w-[1500px] px-20">
                <div class="lg:flex gap-20">
                    <div>
                        <section>
                            <h2 class="title2">À quoi ça sert un <span class="lowercase" v-if="profileSelected.role">{{
            profileSelected.role.name
        }}</span> ?</h2>
                            <p>{{ descriptionRole }}</p>
                        </section>
                        <section>
                            <h2 class="title2">Ce qui m’intéresse dans le fait d’être <span class="lowercase"
                                    v-if="profileSelected.role">{{
            profileSelected.role.name }}</span></h2>
                            <p>{{ profileSelected.personal_approach }}</p>
                        </section>
                    </div>
                    <div class="lg:mt-32">
                        <section>
                            <h2 class="title2">Si vous venez chez moi, il faut que vous sachiez que… </h2>
                            <p> {{ profileSelected.personal_presentation }}</p>
                        </section>
                        <section>
                            <h2 class="title2">Qui je suis professionnellement</h2>
                            <p>{{ profileSelected.bio }}</p>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref, inject } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import { useRoute, useRouter } from 'vue-router';
import client from '@/api/client';
import { UserCircleIcon } from '@heroicons/vue/24/outline';

const descriptionRoles = ref([]);
const descriptionRole = ref('');
const route = useRoute();
const router = useRouter();
let profileId = ref(route.params.id);
const profileSelected = ref(null);
const errorMessage = ref('');

const fetchProfile = async () => {
    console.log('profileId:', profileId.value);
    try {
        const response = await client.get(`/api/profiles/${profileId.value}`);
        profileSelected.value = response.data;

        // Check if the role is 'Administrateur·trice' and redirect to 404
        if (profileSelected.value.role.name === 'Administrateur·trice') {
            router.push('/404');
        }
        fetchDescrptionRoles();
    } catch (error) {
        console.error('Failed to fetch profile details:', error);
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
};

const fetchDescrptionRoles = async () => {
    try {
        const response = await client.get('/api/roles');
        descriptionRoles.value = response.data;
        const descriptionRoleText = descriptionRoles.value.filter(role => role.id === profileSelected.value.role_id);
        descriptionRole.value = descriptionRoleText[0].description;

    } catch (error) {
        console.error('Failed to fetch roles:', error);
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
};

onMounted(() => {
    fetchProfile();
});
</script>
