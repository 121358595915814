<template>
    <div class="overflow-hidden rounded-t-lg bg-white mx-auto" v-if="currentProfile">
        <h2 class="sr-only" id="profile-overview-title">Profile Overview</h2>
        <div class="bg-white p-6">
            <div class="sm:flex sm:items-center sm:justify-between">
                <div class="sm:flex sm:space-x-5">
                    <div class="flex-shrink-0">
                        <img class="mx-auto h-20 w-20 rounded-full object-cover" sr
                            :src="currentProfile.profile_picture_url" alt=""
                            v-if="currentProfile.profile_picture_url" />
                        <UserCircleIcon class="h-20 w-20  text-gray-300" aria-hidden="true" v-else />
                    </div>

                    <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                        <p class="text-sm font-medium text-gray-600">Bienvenue,</p>
                        <p class="text-xl font-semibold text-gray-900 sm:text-2xl">{{ currentProfile.user.name }}</p>
                        <p
                            class="text-sm font-medium text-gray-600 my-3 flex items-center justify-center sm:justify-start">
                            <span :class="'bg-role' + currentProfile?.role?.id"
                                class="mr-2 inline-flex items-center rounded-full bg-indigo-100 px-2 py-1 text-xs font-medium text-indigo-700">

                                {{ currentProfile.role.name }}
                            </span>
                            <ProfileSwitch /><span class="mr-4">
                                <router-link to="/utilisateurs/profil/creer-mon-profil"><button type="button"
                                        class="ml-2 inline-block rounded-full bg-lime-200 p-1 text-black shadow-sm hover:bg-indigo-500 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                        <PlusIcon class="h-3 w-3 gap-2" aria-hidden="true" />
                                    </button></router-link></span>
                        </p>

                        <div
                            v-if="(currentProfile.role.name !== 'Coordinateur·trice') || (currentProfile.role.name === 'Assistant de formation') || (currentProfile.role.name === 'Administrateur·trice')">
                            <p v-if="currentProfile.structures.length > 0">
                                <span
                                    class="mr-1 inline-flex items-center gap-x-1.5 rounded-full px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200"
                                    v-for="(attachedStructure) in currentProfile.structures"
                                    :key="attachedStructure.id">
                                    <svg class="h-1.5 w-1.5 fill-indigo-500" viewBox="0 0 6 6" aria-hidden="true">
                                        <circle cx="3" cy="3" r="3" />
                                    </svg>{{
        attachedStructure.name
    }}</span>
                            </p>
                            <div v-else>
                                <p v-if="currentProfile.role.name === 'Formateur·trice métier' || currentProfile.role.name === 'Formateur·trice référent·e'"
                                    class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                                    Aucune structure attachée</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-5 flex justify-center sm:mt-0">
                    <div>
                        <div class="mt-4 flex items-center">
                            <button v-if="displayEditDialog"
                                @click.prevent="displayEditDialog = true; formUser.name = currentProfile.user.name">Mes
                                infos</button>
                            <router-link :to="'/utilisateurs/profil/modifier-mon-profil/' + currentProfile?.id">
                                <button
                                    class="rounded-full mr-2 flex items-center justify-center bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Modifier
                                    mon profil</button></router-link> <router-link
                                :to="'/utilisateurs/profil/' + currentProfile?.id"> <button
                                    v-if="currentProfile.role.name !== 'Administrateur·trice'"
                                    class="rounded-full mr-2 flex items-center justify-center bg-white px-3 py-2 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">Voir
                                    mon profil</button></router-link>

                        </div>
                        <div class="inline-flex rounded-md shadow-sm">

                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
    <AdminDialog @confirm="editItem" :typeEdition="'modification'" :title="confirmTexts[0].title"
        @close="displayEditDialog = false" v-model="typeModelForm" :objectToModify="formUser"
        v-if="displayEditDialog" />
    <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
        :detail="notificationMessage" @close="showNotification = false" />
</template>

<script setup>
/* eslint-disable */
import { inject, ref, watch } from 'vue';
import ProfileSwitch from '@/components/profiles/ProfileSwitch.vue';
import { UserCircleIcon } from '@heroicons/vue/24/solid';
import { PlusIcon } from '@heroicons/vue/24/solid';
import AdminDialog from '@/components/dialogs/AdminDialog.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import client from '@/api/client';

const store = inject('store');
const currentProfile = ref(store.chosenProfile);
const profiles = ref(store.userProfiles);
const showNotification = ref(false);
const notificationType = ref('');
const notificationTitle = ref('');
const notificationMessage = ref('');
const displayEditDialog = ref(false);
const formUser = ref([{
    name: '',
}]);


watch(() => store.chosenProfile, (newProfile) => {
    currentProfile.value = newProfile;
});
const confirmTexts = [
    {
        title: 'Éditer mes infos personnelles',
        message: 'Voulez-vous modifier vos informations personnelles ?',
        primaryActionLabel: 'supprimer',
        secondaryActionLabel: 'annuler'
    },
]

const typeModelForm = ref([
    {
        field: 'name',
        label: 'Prénom et Nom',
        type: 'text',
        entry: '',
    },
]);

const editItem = async (arrayToPost) => {
    const typeFormData = arrayToPost.value.reduce((formData, item) => {
        formData[item.field] = item.entry;
        return formData;
    }, {});
    console.log(typeFormData.name);
    const response = await client.put(`api/admin/user/${currentProfile.value.user.id}`, {
        name: typeFormData.name,
    });
};

</script>
