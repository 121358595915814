<template>
  <div class="md:flex md:items-center md:justify-between">
    <div class="min-w-0 flex-1 mt-5 mb-5">
      <h2 class="text-2xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">Ma promotion
      </h2>
    </div>
  </div>
  <div>
    <div class="bg-white shadow sm:rounded-lg">
      <div class=" px-4 py-5 sm:p-6">
        <div class="mt-2 sm:flex sm:items-start sm:justify-between">
          <div>
            <h3 class="text-base font-semibold leading-6 text-gray-900">{{ trainingAction?.degree.name }} - <span
                class="font-medium text-indigo-700">{{ trainingAction?.degree.city }} - {{
                  trainingAction?.year }}</span>
            </h3>
            <div class="mb-4">Mon parcours FEST</div>

          </div>
        </div>
        <div class="">
          <nav class="mx-auto" aria-label="Progress">
            <ol role="list" class="overflow-hidden rounded-md lg:flex lg:rounded-none">
              <li v-for="( step, stepIdx ) in steps " :key="step.id" class="relative overflow-hidden lg:flex-1">
                <div class="absolute inset-0 lg:flex items-center z-0 -mt-11 hidden " aria-hidden="true">
                  <div class="h-0.5 w-full bg-indigo-600" />
                </div>
                <div
                  :class="[stepIdx === 0 ? 'rounded-t-md border-b-0' : '', stepIdx === steps.length - 1 ? 'rounded-b-md border-t-0' : '', 'overflow-hidden border lg:border-0']">
                  <a v-if="step.status === 'complete'" :href="step.href" class="group">
                    <span :class="[stepIdx !== 0 ? 'lg:pl-9' : '', 'flex lg:flex-col px-6 py-5 text-sm font-medium']">
                      <span class="flex-shrink-0">
                        <span
                          class="relative z-10 flex h-10 w-10 items-center justify-center rounded-full bg-indigo-600">
                          <CheckIcon class="h-6 w-6 text-white" aria-hidden="true" />
                        </span>
                      </span>
                      <span class="pl-4 lg:pl-0 mt-0.5 lg:mt-2 flex min-w-0 flex-col">
                        <span class="text-sm font-semibold  text-indigo-600">{{ step.name }}</span>
                        <span class="text-sm font-medium text-gray-500">{{ step.start_date }}</span>
                      </span>
                    </span>
                  </a>
                  <a v-else-if="step.status === 'current'" :href="step.href" aria-current="step">
                    <span :class="[stepIdx !== 0 ? 'lg:pl-9' : '', 'flex lg:flex-col px-6 py-5 text-sm font-medium']">
                      <span class="flex-shrink-0">
                        <span
                          class="relative z-10 flex h-10 w-10 items-center justify-center rounded-full border-2 border-indigo-600">
                          <span class="text-indigo-600">{{ step.id }}</span>
                        </span>
                      </span>
                      <span class="pl-4 lg:pl-0 mt-0.5 lg:mt-2 flex min-w-0 flex-col">
                        <span class="text-sm  font-semibold text-indigo-600">{{ step.name }}</span>
                        <span class="text-sm font-medium text-gray-500">{{ step.start_date }}</span>
                      </span>
                    </span>
                  </a>
                  <a v-else :href="step.href" class="group">
                    <span :class="[stepIdx !== 0 ? 'lg:pl-9' : '', 'flex lg:flex-col px-6 py-5 text-sm font-medium']">
                      <span class="flex-shrink-0">
                        <span
                          class="relative z-10 bg-white flex h-10 w-10 items-center justify-center rounded-full border-2">
                          <span class="text-gray-500">{{ step.id }}</span>
                        </span>
                      </span>
                      <span class="pl-4 lg:pl-0 mt-0.5 lg:mt-2 flex min-w-0 flex-col">
                        <span class="text-sm font-semibold  text-indigo-600">{{ step.name }}</span>
                        <span class="text-sm font-medium text-gray-500">{{ step.start_date }}</span>
                      </span>
                    </span>
                  </a>
                  <template v-if="stepIdx !== 0">
                    <!-- Separator -->
                    <div class="absolute inset-0 left-0 top-0 hidden w-3 lg:block" aria-hidden="true">
                    </div>
                  </template>
                </div>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <AlertMessage v-if="displayAlert && !startTrainingAction" :type="alertType" :title="alertMessage"
        :content="alertContent" class="mb-5" />
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref, inject } from 'vue';
import { CheckIcon } from '@heroicons/vue/24/solid';
import SelectTrainingAction from '@/components/training-actions/SelectTrainingAction.vue';
import AlertMessage from '@/components/alert/AlertMessage.vue';
import client from '@/api/client';

const store = inject('store');
const widthProgressBar = ref('0%');
const selectTrainingAction = ref(false);
const alertMessage = ref('');
const alertType = ref('info');
const displayAlert = ref(false);
const alertContent = ref('');
const startTrainingAction = ref(false);
const currentTrainingActions = ref([]);
const trainingAction = ref(null);
const profileId = store.chosenProfile.id;

const steps = [
  { id: '01', name: 'RDV 1', start_date: '', href: '/etape/1', status: 'complete' },
  { id: '02', name: 'RDV2', start_date: '', href: '/etape/2', status: 'upcoming' },
  { id: '03', name: 'Situation 1', start_date: '', href: '/etape/3', status: 'upcoming' },
  { id: '04', name: 'Situation 2', start_date: '', href: '/etape/4', status: 'upcoming' },
  { id: '05', name: 'Situation 3', start_date: '', href: '/etape/3', status: 'upcoming' },
  { id: '06', name: 'Situation 4', start_date: '', href: '/etape/3', status: 'upcoming' },
  { id: '07', name: 'Bilan', start_date: '', href: '/etape/1', status: 'upcoming' },
]

const fetchCurrentTrainingActions = async () => {
  const response = await client.get(`/api/training-actions/profile/${profileId}`);
  currentTrainingActions.value = response.data;
  if (currentTrainingActions.value.length !== 0) {
    if (currentTrainingActions.value[0].is_ongoing) {
      trainingAction.value = currentTrainingActions.value[0];
    }
  }
};

onMounted(() => {
  fetchCurrentTrainingActions();
});

</script>
