<template>
  <AppLayout>
    <div class="mx-auto 2xl:max-w-9xl px-6 sm:px-10 -mt-10">
      <div class="hero-structures max-w-[1520px] mx-auto">
        <h1 class="title1 mb-10 text-white">Structures apprenantes</h1>

        <p class="max-w-[900px] text-md md:text-lg lg:text-xl lg:leading-8 text-white" v-if="post_event"> <span
            class="font-medium text-white-300">Pour
            choisir dans quelle structure tu pourras réaliser ton alternance,
            il faut que tu émettes des vœux
            de structures. Pour cela, il faut que tu choisisses dans cette liste les 3
            structures pour lesquelles tu souhaites candidater en priorité, et les 2 en optionnel.</span> Nous
          respecterons au mieux
          tes vœux
          en fonction des vœux des autres apprenants. Pour choisir une structure, tu peux faire un premier tri en
          mettant en favori les structures qui t'intéressent. Ensuite, tu pourras définir parmi ces favoris quelles
          structures tu retiens en priorité et en optionnel.</p>
        <p class="max-w-[900px] text-md md:text-lg lg:text-xl lg:leading-8  text-white" v-else> <span
            class="font-medium text-white-300">Pour
            choisir
            quelles structures tu
            vas
            rencontrer
            lors de
            l'alternance day,
            il faut que tu émettes des vœux
            de structures que tu souhaites rencontrer. Pour cela, il faut que tu choisisses dans cette liste les 3
            structures que tu souhaites rencontrer en priorité, et les 2 en optionnel.</span> Nous respecterons au mieux
          tes vœux
          en fonction des vœux des autres apprenants. Pour choisir une structure, tu peux faire un premier tri en
          mettant en favori les structures qui t'intéressent. Ensuite, tu pourras définir parmi ces favoris quelles
          structures tu retiens en priorité et en optionnel.</p>
        <div class="text-right mb-4 flex items-center justify-end mt-10">
          <span class="mr-2 text-indigo-600 ml-auto font-medium text-white" v-if="filteredStructures">{{ totalStructures
            }}
            structures
            sélectionnées</span>
          <button type="button" @click="displayFilters = true" :disabled="displayFilters"
            class="cursor-pointer inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <AdjustmentsVerticalIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Filtrer
          </button>
        </div>
      </div>
      <div class="columns-card max-w-[1520px] mx-auto" v-if="relevantStructures.length > 0">
        <div v-for=" relevantStructure  in  relevantStructures " :key="relevantStructure.id" class="break-inside-avoid">
          <StructureCard :displayedStructure="relevantStructure" :typeCard="'list'"
            @displayStructure="displayStructure(relevantStructure)" />
        </div>
      </div>

      <StructureFilters v-model="paramsSearchStructure" v-if="displayFilters" @update="refreshSearch"
        @close="displayFilters = false;" />
      <StructureAbstract :chosenStructure="selectedStructure" v-if="displayStructureAbstract"
        :show="displayStructureAbstract" @close="displayStructureAbstract = false" />
      <Pagination :current-page="currentPage" :total-pages="totalPages" @change-page="changePage" />
    </div>
  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, inject, computed } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import Pagination from '@/components/lists/PaginationTables.vue';
import StructureCard from '@/components/structures/StructureCard.vue';
import StructureAbstract from '@/components/structures/StructureAbstract.vue';
import StructureFilters from '@/components/structures/StructureFilters.vue';
import client from '@/api/client';
import { AdjustmentsVerticalIcon } from '@heroicons/vue/24/outline';
import fetchEventServices from '@/services/EventServices.js';

const store = inject('store');
const currentProfile = store.chosenProfile;

const relevantStructures = ref([]);
const selectedStructure = ref(null);
const displayStructureAbstract = ref(false);
const displayFilters = ref(false);
const chosenEvent = ref([]);
const post_event = ref(false);

const displayStructure = (structureToDisplay) => {
  displayStructureAbstract.value = true;
  selectedStructure.value = structureToDisplay;
};
const currentPage = ref(1);
const totalPages = ref(1);
// const pageSize = 10;
const totalStructures = ref(0);
const paramsSearchStructure = ref({
  publics: [],
  degrees: [],
  keywords: [],
  types: [],
  legalForms: [],
});

const filteredStructures = computed(() => {
  const hasPublics = paramsSearchStructure.value.publics.length > 0;
  const hasDegrees = paramsSearchStructure.value.degrees.length > 0;
  const hasKeywords = paramsSearchStructure.value.keywords.length > 0;
  const hasTypes = paramsSearchStructure.value.types.length > 0;
  const hasLegalForms = paramsSearchStructure.value.legalForms.length > 0;
  const hasFilters = hasPublics || hasDegrees || hasKeywords || hasTypes || hasLegalForms;
  return hasFilters;
});
const refreshSearch = async (paramsSearch) => {
  fetchStructures(1, paramsSearch);
}

const fetchStructures = async (page, paramsSearch) => {
  const params = new URLSearchParams();
  try {
    params.append('page', page);
    params.append('publics[]', paramsSearch.publics.join(','));
    params.append('degrees[]', paramsSearch.degrees.join(','));
    params.append('types[]', paramsSearch.types.join(','));
    params.append('keywords[]', paramsSearch.keywords);
    params.append('legal_forms[]', paramsSearch.legalForms.join(','));
    const response = await client.get(`/api/structures/search/filter?${params}`);
    relevantStructures.value = response.data.data;
    currentPage.value = response.data.current_page;
    totalPages.value = response.data.last_page;
    totalStructures.value = response.data.total;
  } catch (error) {
    console.error('Failed to fetch publics details:', error);
  }
};

const changePage = (page) => {
  fetchStructures(page, paramsSearchStructure.value);
};

onMounted(async () => {
  store.setStructures();
  fetchStructures(1, paramsSearchStructure.value);
  if (currentProfile?.training_actions) {
    chosenEvent.value = await fetchEventServices.fetchEventById(currentProfile.training_actions[0]?.event_id);
  }
  if (new Date(chosenEvent.value.end_date) < new Date()) {
    post_event.value = true;
  }
});
</script>
