<template>
  <DocumentLayout>
    <template v-slot:title>mes dossiers</template>
    <template v-slot:full>
      <SmallLoader v-if="isLoading" :text="'Chargement en cours'" class="mb-10" />
      <ActionsManager :folders="relevantTrainingActions" :key="componentKey" @update="fetchActions"
        v-if="relevantTrainingActions" />
    </template>
  </DocumentLayout>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref, inject } from 'vue';
import DocumentLayout from '@/layouts/DocumentLayout.vue';
import ActionsManager from '@/components/files/ActionsManager.vue';
import fetchServices from '@/services/fetchServices.js';
import SmallLoader from '@/components/app/SmallLoader.vue';

const currentPage = ref(1);
const totalPages = ref(1);
const totalItems = ref(0);
const pageSize = 10;

const relevantTrainingActions = ref([]);
const componentKey = ref(0);
const isLoading = ref(true);

const fetchActions = () => {
  isLoading.value = true;
  fetchServices.fetchTrainingActions(currentPage.value, pageSize).then((data) => {
    relevantTrainingActions.value = data.data;
    currentPage.value = data.current_page;
    totalPages.value = data.last_page;
    totalItems.value = data.total;
    componentKey.value += 1;
    isLoading.value = false;
  });
}

onMounted(() => {
  fetchActions();
});
</script>
