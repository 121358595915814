<template>
    <AppLayout>
        <div class="mx-auto 2xl:max-w-9xl detail-page !px-0 lg:px-8" v-if="nextEvent">
            <div class="hero-simple px-8 pb-15 xl:px-0 mb-20">
                <h1 class="text-center mb-7 mt-16 text-md lg:text-2xl xl:text-6xl text-white">Rejoins l'Alternance Day
                    le</h1>
                <img src="@/assets/img/elements/date-alternance-day.png" alt="" class="mx-auto w-[150px] lg:w-[200px]">
            </div>
            <router-link to="structures">
                <button
                    class="mb-5 mx-auto flex items-center rounded-full bg-white py-6 px-8 font-medium text-lg shadow-lg ring-1 ring-inset ring-indigo-700 hover:bg-indigo-100 text-indigo-700">
                    <MagnifyingGlassIcon class="ml-0.5 h-6 w-6 mr-3 stroke-2" aria-hidden="true" /> Recherche ta
                    structure
                </button></router-link>
            <div class="container-page bg-white pt-10 rounded-3xl ml px-8 xl:px-20">

                <p class="text-xl text-indigo-700 font-semibold tracking-normal title mt-10">Découvre ici les structures
                    que
                    tu
                    souhaites rencontrer
                </p>
                <p>Tu recherches une structure pour ta formation en alternance avec Trajectoire Formation ? L’Aternance
                    Day est l’occasion de rencontrer les structures qui t’intéressent. Navigue dans le catalogue de
                    structures et fais tes vœux pour rencontrer les structures de ton choix lors de l'Alternance Day.
                </p>
                <section>
                    <h2 class="title2 flex items-center">
                        <BuildingOffice2Icon class="w-8 h-8 mr-2" /> Connaître les types de structure
                    </h2>
                    <div class="flex flex-wrap grid xs:grid-cols-2 sm:grid-cols-3 gap-3 md:grid-cols-4 lg:grid-cols-5">
                        <div v-for="typeStructure in availableTypes" :key="typeStructure.id"
                            class="md:h-[110px] px-6 py-4 leading-4 md:leading-5 text-center inline-flex items-center  justify-center rounded-md bg-lime-100 px-2.5 py-1.5 text-sm md:text-md font-normal text-indigo-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">

                            <div class="text-center">{{ typeStructure.name }}</div>
                        </div>
                    </div>
                </section>
                <section>
                    <h2 class="title2 flex items-center">
                        <UserIcon class="w-8 h-8 mr-2" />Les structures par public
                    </h2>
                    <div class="flex flex-wrap grid xs:grid-cols-2 sm:grid-cols-3 gap-3 md:grid-cols-4 lg:grid-cols-5">
                        <div v-for="typePublic in availablePublics" :key="typePublic.id"
                            class=" h-[110px] px-6 py-4  leading-5 text-center inline-flex items-center  justify-center rounded-md bg-indigo-100 px-2.5 py-1.5 text-md font-normal text-indigo-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">

                            {{ typePublic.name }}
                        </div>
                    </div>
                </section>

                <div class="grid lg:grid-cols-2 gap-4 mt-20">
                    <div>
                        <a href="https://fest.trajectoire-formation.com/" target="_blank"
                            class="flex items-center mb-2 mx-auto flex items-center rounded-full bg-white py-4 px-4 font-medium text-lg shadow-lg ring-1 ring-inset ring-indigo-700 hover:bg-indigo-100 text-indigo-700">
                            <div class="bg-indigo-100 min-w-24 rounded-full object-center flex items-center mr-4"><img
                                    src="@/assets/img/photos/equipe.png" alt=""
                                    class="object-cover min-w-24 w-24 h-24 rounded-full">
                            </div>
                            <span class="leading-6"> Découvre la Fest
                                avec Trajectoire Formation</span>

                        </a>
                    </div>

                    <div>
                        <router-link to="/prochain-evenement"
                            class="flex items-center mb-5 mx-auto flex items-center rounded-full bg-white py-4 px-4 font-medium text-lg shadow-lg ring-1 ring-inset ring-indigo-700 hover:bg-indigo-100 text-indigo-700">
                            <div
                                class="mr-4 bg-indigo-100 min-w-24 w-24 h-24 px-8 rounded-full object-center flex items-center">
                                <img src="@/assets/img/elements/fest.png" alt="" class="object-contain">
                            </div>
                            <span class="leading-6"> Découvre l'Alternance Day</span>

                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <HomeDefault />
        </div>
    </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, reactive } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import fetchEventServices from '@/services/EventServices.js';
import {
    BuildingOffice2Icon, UserIcon, MagnifyingGlassIcon,
} from '@heroicons/vue/24/outline'
import HomeDefault from '@/components/home/HomeDefault.vue';

const availableTypes = ref([]);
const availablePublics = ref([]);
const nextEvent = ref(null);
import fetchServices from '@/services/fetchServices.js';

const stats = reactive([
    { name: 'Structures', stat: null },
    { name: 'Utilisateurs', stat: '120' },
    { name: 'Organisations', stat: '24' },
]);

onMounted(async () => {
    try {
        fetchServices.fetchTypesPublics().then((data) => {
            availableTypes.value = data;
        });
        fetchServices.fetchStructuresPublics().then((data) => {
            availablePublics.value = data;
        });
    } catch (error) {
        console.error('Failed to fetch the next event:', error);
    }

    try {
        const response = await client.get(`/api/next-event`);
        nextEvent.value = response.data;
    } catch (error) {
        console.error('Failed to fetch the next event:', error);
        return error;
    }
});

</script>
