<template>
    <footer class="text-gray-600 body-font">
        <div class="container px-5 py-8 mx-auto flex items-center sm:flex-row flex-col justify-center">
            <a class="flex title-font font-medium items-center md:justify-start justify-center text-gray-900">
                <img src="@/assets/img/logos/logo-tf.png" width="200px" alt="Trajectoire Fest" />
            </a>
            <p class="text-sm text-gray-500 sm:ml-4 sm:pl-4 sm:border-l-2 sm:border-gray-200 sm:py-2 sm:mt-0 mt-4">Tous
                droits réservés —
                <a href="/informations-legales" class="text-gray-600 ml-1" rel="noopener noreferrer"
                    target="_blank">Informations légales</a>
            </p>
        </div>
    </footer>
</template>
