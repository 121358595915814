<template>
    <AppLayout>
        <div class="mx-auto 2xl:max-w-9xl detail-page !px-0 lg:px-8" v-if="nextEvent">
            <div class="hero-simple px-6 sm:px-10 pb-15 xl:px-0">
                <h1 class="title1 mb-5 text-white">L'Alternance Day</h1>
                <h2 class="pb-10 text-cyan-300">Le 28 juin de 9h à 13h
                </h2>
            </div>
            <div class="">
                <div class="container-page pt-6 bg-white rounded-xl">
                    <div class="px-6 sm:px-10 lg:flex gap-2 mx-auto">
                        <div class="mx-auto">
                            <section>
                                <h2 class="title2">L'alternance day, c'est quoi ?</h2>
                                <p> Un événement pour créer la rencontre entre futurs apprenants et structures
                                    apprenantes
                                    de la
                                    rentrée
                                    2024.

                                    Le but ? Permettre aux apprenants de choisir une structure d'alternance en ayant pu
                                    découvrir les
                                    structures
                                    "pour de vrai". C'est un temps de rencontres qui se différencie des formats type
                                    "job
                                    dating" : nous
                                    souhaitons avoir des temps collectifs qui laissent le temps d'échanger en profondeur
                                    pour
                                    cerner
                                    l'intérêt
                                    et le potentiel de chaque structure et apprenant. Cet événement fait vivre une
                                    communauté
                                    d'apprentissage,
                                    entre apprenants, structures et organisme de formation.</p>
                            </section>
                            <section>
                                <h2 class="title2"> L'alternance day, comment ça se passe ?</h2>

                                <p> Les apprenants vont à la rencontre des structures organisées par îlots
                                    géographiques.

                                    Pour choisir quels îlots (et donc quelles structures) l'apprenant veut rencontrer
                                    dans
                                    les
                                    différentes
                                    rotations, il doit faire des vœux de structures via cette plateforme.</p>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import fetchEventServices from '@/services/EventServices.js';

const nextEvent = ref(null);
const startDate = ref(null);
const endDate = ref(null);
const monthEvent = ref(null);
const yearEvent = ref(null);
const dayEvent = ref(null);

onMounted(async () => {
    try {
        nextEvent.value = await fetchEventServices.fetchNextEvent();

        function formatDate(date) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(date).toLocaleDateString('fr-FR', options);
        }

        function formatTime(date) {
            const options = { hour: 'numeric', minute: 'numeric' };
            return new Date(date).toLocaleTimeString('fr-FR', options);
        }
        const startDateDay = formatDate(nextEvent.value.start_date);
        console.log(startDateDay);
        const startDateFormatted = formatTime(nextEvent.value.start_date);
        const endDateFormatted = formatTime(nextEvent.value.end_date);
        console.log(`de ${startDateFormatted} à ${endDateFormatted}`);

    } catch (error) {
        console.error('Failed to fetch the next event:', error);
    }
});

</script>
