<template>
  <div class="bg-white">
    <div>
      <!-- Mobile filter dialog -->
      <TransitionRoot as="template" :show="mobilefoldersOpen">
        <Dialog class="relative z-40 lg:hidden" @close="mobilefoldersOpen = false">
          <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0"
            enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100"
            leave-to="opacity-0">
            <div class="fixed inset-0 bg-black bg-opacity-25" />
          </TransitionChild>

          <div class="fixed inset-0 z-40 flex">
            <TransitionChild as="template" enter="transition ease-in-out duration-300 transform"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
              leave-to="translate-x-full">
              <DialogPanel
                class="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                <div class="flex items-center justify-between px-4">
                  <h2 class="text-lg font-medium text-gray-900">folders</h2>
                  <button type="button"
                    class="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                    @click="mobilefoldersOpen = false">
                    <span class="sr-only">Close menu</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <!-- folders -->
                <form class="mt-4 border-t border-gray-200">
                  <h3 class="sr-only">Categories</h3>
                  <Disclosure as="div" v-for="section in folders" :key="section.id"
                    class="border-t border-gray-200 px-4 py-6" v-slot="{ open }">
                    <h3 class="-mx-2 -my-3 flow-root">
                      <DisclosureButton
                        class="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                        <span class="font-medium text-gray-900">{{ section.name }}</span>
                        <span class="ml-6 flex items-center">
                          <PlusIcon v-if="!open" class="h-5 w-5" aria-hidden="true" />
                          <MinusIcon v-else class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </DisclosureButton>
                    </h3>
                    <DisclosurePanel class="pt-6">
                      <div class="space-y-6">
                        <div v-for="(option, optionIdx) in section.options" :key="option.value"
                          class="flex items-center">
                          <input :id="`filter-mobile-${section.id}-${optionIdx}`" :name="`${section.id}[]`"
                            :value="option.value" type="checkbox" :checked="option.checked"
                            class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                          <label :for="`filter-mobile-${section.id}-${optionIdx}`"
                            class="ml-3 min-w-0 flex-1 text-gray-500">{{ option.label }}</label>
                        </div>
                      </div>
                    </DisclosurePanel>
                  </Disclosure>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>

      <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <button type="button" class="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
          @click="mobileFiltersOpen = true">
          <span class="sr-only">Filters</span>
          <FunnelIcon class="h-5 w-5" aria-hidden="true" />
        </button>
        <section aria-labelledby="products-heading" class="pb-24 pt-6">
          <h2 id="products-heading" class="sr-only">Products</h2>

          <!-- folders -->
          <form class="hidden lg:block">
            <router-link to="/documents"><button class="flex items-center pb-4 text-left">
                <ChevronLeftIcon class="h-8 w-8 mr-3 text-indigo-700" /> Voir toutes les actions
              </button></router-link>
            <div class="flex items-center border-b border-gray-200 py-6">
              <router-link :to="'/documents/action/' + actionSelected?.id" class="flex items-center">
                <FolderIcon class="h-8 w-8 mr-3 text-indigo-700" />
                <p class="text-indigo-700 font-semibold">{{ actionSelected?.degree?.name }} - {{ actionSelected?.year }}
                </p>
              </router-link>
            </div>
            <Disclosure as="div" v-for="section in relevantFolders.folders" :key="section.id"
              class="border-b border-gray-200 py-6" v-slot="{ open }">
              <h3 class="-my-3 flow-root">
                <div
                  class="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                  <router-link :to="'/documents/dossier/' + section.id" @click.enter="$emit('changePage', section.id)">
                    <div class="flex items-center">
                      <FolderIcon class="h-8 w-8 mr-3"
                        :class="folderSelected?.id === section.id ? 'text-indigo-700' : ''" /> <span
                        class="font-medium text-gray-900"
                        :class="folderSelected?.id === section.id ? 'text-indigo-700' : ''">{{ section.name
                        }}</span>
                    </div>
                  </router-link>
                  <DisclosureButton>
                    <span class="ml-6 flex items-center">
                      <PlusIcon v-if="!open" class="h-5 w-5" aria-hidden="true" />
                      <MinusIcon v-else class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </DisclosureButton>
                </div>
              </h3>
              <DisclosurePanel class="pt-6">
                <div class="space-y-4">
                  <div v-for="( folder, optionIdx ) in section.children " :key="folder.id" class="flex items-center">
                    <router-link :to="'/documents/dossier/' + folder.id">
                      <div class="flex items-center">
                        <FolderIcon class="h-6 w-6 mr-1 text-indigo-400" />
                        <label :for="`filter-${folder.id}-${optionIdx}`" class="text-sm text-gray-600">{{
                          folder.name }}</label>
                      </div>
                    </router-link>
                  </div>
                  <div v-for="( document, optionIdx ) in section.documents " :key="document.id"
                    class="flex items-center">
                    <router-link :to="'/documents/' + document.id">
                      <div class="flex items-center">
                        <DocumentIcon class="h-6 w-6 mr-1 text-teal-400" />
                        <label :for="`filter-${document.id}-${optionIdx}`" class="text-sm text-gray-600">{{
                          document.name }}</label>
                      </div>
                    </router-link>
                  </div>
                </div>
              </DisclosurePanel>
            </Disclosure>
          </form>

        </section>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref, inject } from 'vue';
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { XMarkIcon, FolderIcon, DocumentIcon } from '@heroicons/vue/24/outline'
import { ChevronLeftIcon, FunnelIcon, MinusIcon, PlusIcon, Squares2X2Icon } from '@heroicons/vue/20/solid'
import fetchServices from '@/services/fetchServices.js';
import client from '@/api/client';
import { useRoute, useRouter } from 'vue-router';

const props = defineProps({
  document: [Number, String],
  action: [Number, String],
  folder: [Number, String],
});

const route = useRoute();
const emit = defineEmits(['update', 'changePage']);
const folderSelected = ref(null);
const actionSelected = ref(null);
const componentKey = ref(0);
const currentPage = ref(1);
const totalPages = ref(1);
const totalItems = ref(0);
const pageSize = 10;
const relevantTrainingActions = ref([]);
const relevantFolders = ref([]);

const actions = ref([]);

const folders = ref([
  {
    id: '1',
    name: 'FEST',
    action: 3,
    options: [/*...*/],
    created_at: "2024-08-22T13:21:03.000000Z",
    updated_at: "2024-08-22T13:21:03.000000Z",
    documents: [
      {
        id: 3,
        name: "Document dans Fest",
        description: "Description pour le document dans le dossier Documents Internes",
        folder_id: 3,
        created_at: "2024-08-22T13:21:03.000000Z",
        updated_at: "2024-08-22T13:21:03.000000Z",
        files: [
          {
            id: 3,
            document_id: 3,
            file_path: "documents/sample.pdf",
            created_at: "2024-08-22T13:21:03.000000Z",
            updated_at: "2024-08-22T13:21:03.000000Z"
          }
        ]
      }
    ]
  },
  {
    id: '2',
    name: 'FOAD',
    action: 2,
    options: [/*...*/],
    created_at: "2024-08-22T13:21:03.000000Z",
    updated_at: "2024-08-22T13:21:03.000000Z",
    documents: [
      {
        id: 2,
        name: "Document dans Foad",
        description: "Description pour le document dans le dossier Productions Apprenants",
        folder_id: 2,
        created_at: "2024-08-22T13:21:03.000000Z",
        updated_at: "2024-08-22T13:21:03.000000Z",
        files: [
          {
            id: 2,
            document_id: 2,
            file_path: "documents/sample.pdf",
            created_at: "2024-08-22T13:21:03.000000Z",
            updated_at: "2024-08-22T13:21:03.000000Z"
          }
        ]
      }
    ]
  },
  {
    id: '3',
    name: 'UC1',
    href: '#',
    action: 1,
    options: [/*...*/],
    created_at: "2024-08-22T13:21:03.000000Z",
    updated_at: "2024-08-22T13:21:03.000000Z",
    documents: [
      {
        id: 1,
        name: "Document dans le dossier Ressources",
        description: "Description pour le document dans le dossier Ressources que je modifie",
        folder_id: 1,
        created_at: "2024-08-22T13:21:03.000000Z",
        updated_at: "2024-08-23T16:48:24.000000Z",
        files: [
          {
            id: 1,
            document_id: 1,
            file_path: "documents/sample.pdf",
            created_at: "2024-08-22T13:21:03.000000Z",
            updated_at: "2024-08-22T13:21:03.000000Z"
          }
        ]
      },
      {
        id: 2,
        name: "Document 2",
        description: "Description pour le document dans le dossier Ressources que je modifie",
        folder_id: 1,
        created_at: "2024-08-22T13:21:03.000000Z",
        updated_at: "2024-08-23T16:48:24.000000Z",
        files: [
          {
            id: 1,
            document_id: 1,
            file_path: "documents/sample.pdf",
            created_at: "2024-08-22T13:21:03.000000Z",
            updated_at: "2024-08-22T13:21:03.000000Z"
          }
        ]
      }
    ]
  },
  {
    id: '4',
    name: 'UC2',
    href: '#',
    action: 1,
    options: [/*...*/],
    created_at: "2024-08-22T13:21:03.000000Z",
    updated_at: "2024-08-22T13:21:03.000000Z",
    documents: [
      {
        id: 1,
        name: "Document dans le dossier uc2",
        description: "Description pour le document dans le dossier Ressources que je modifie",
        folder_id: 1,
        created_at: "2024-08-22T13:21:03.000000Z",
        updated_at: "2024-08-23T16:48:24.000000Z",
        files: [
          {
            id: 1,
            document_id: 1,
            file_path: "documents/sample.pdf",
            created_at: "2024-08-22T13:21:03.000000Z",
            updated_at: "2024-08-22T13:21:03.000000Z"
          }
        ]
      },
      {
        id: 2,
        name: "Document 2",
        description: "Description pour le document dans le dossier Ressources que je modifie",
        folder_id: 1,
        created_at: "2024-08-22T13:21:03.000000Z",
        updated_at: "2024-08-23T16:48:24.000000Z",
        files: [
          {
            id: 1,
            document_id: 1,
            file_path: "documents/sample.pdf",
            created_at: "2024-08-22T13:21:03.000000Z",
            updated_at: "2024-08-22T13:21:03.000000Z"
          }
        ]
      }
    ]
  },
  {
    id: '5',
    name: 'UC3',
    href: '#',
    action: 1,
    options: [/*...*/],
    created_at: "2024-08-22T13:21:03.000000Z",
    updated_at: "2024-08-22T13:21:03.000000Z",
    documents: [
      {
        id: 1,
        name: "Document dans le dossier uc2",
        description: "Description pour le document dans le dossier Ressources que je modifie",
        folder_id: 1,
        created_at: "2024-08-22T13:21:03.000000Z",
        updated_at: "2024-08-23T16:48:24.000000Z",
        files: [
          {
            id: 1,
            document_id: 1,
            file_path: "documents/sample.pdf",
            created_at: "2024-08-22T13:21:03.000000Z",
            updated_at: "2024-08-22T13:21:03.000000Z"
          }
        ]
      },
      {
        id: 2,
        name: "Document 2",
        description: "Description pour le document dans le dossier Ressources que je modifie",
        folder_id: 1,
        created_at: "2024-08-22T13:21:03.000000Z",
        updated_at: "2024-08-23T16:48:24.000000Z",
        files: [
          {
            id: 1,
            document_id: 1,
            file_path: "documents/sample.pdf",
            created_at: "2024-08-22T13:21:03.000000Z",
            updated_at: "2024-08-22T13:21:03.000000Z"
          }
        ]
      }
    ]
  },
]);
const mobilefoldersOpen = ref(false);

const fetchActions = (idAction) => {
  fetchServices.fetchTrainingActions(currentPage.value, pageSize).then((data) => {
    relevantTrainingActions.value = data.data;
    currentPage.value = data.current_page;
    totalPages.value = data.last_page;
    totalItems.value = data.total;
    actionSelected.value = relevantTrainingActions.value.find((action) => Number(action.id) === Number(idAction));
  });
}
const fetchFolders = async () => {
  let action_id = null;
  if (route.params.idAction) {
    action_id = route.params.idAction;
  } else if (props.action) {
    action_id = props.action;
  }
  try {
    const response = await client.get(`/api/training-actions/${action_id}/folders`);
    relevantFolders.value = response.data;
    if (props.folder) {
      folderSelected.value = relevantFolders.value.folders.find(folder => Number(folder.id) === Number(props.folder));
      // fetchActions(folderSelected.value.id);
    };
    componentKey.value += 1;
  } catch (error) {
    console.log(error);
  }
}

onMounted(async () => {
  fetchFolders();
  if (props.action) {
    try {
      const response = await client.get(`/api/training-actions/${props.action}`);
      actionSelected.value = response.data;
    } catch (error) {
      console.error('Failed to fetch training actions by id', error);
    }
  }
});

</script>
