import { reactive } from 'vue';
import Cookies from 'js-cookie';
import client, { setAccessTokenCookie } from '@/api/client';

const store = reactive({
  selectedStructures: [],
  userProfiles: [],
  chosenProfile: null,

  addStructure(structure) {
    this.selectedStructures.push(structure);
    localStorage.setItem('selectedStructures', JSON.stringify(this.selectedStructures));
  },

  removeStructure(structureId) {
    this.selectedStructures = this.selectedStructures.filter((s) => s.id !== structureId);
    localStorage.setItem('selectedStructures', JSON.stringify(this.selectedStructures));
  },

  clearStructures() {
    this.selectedStructures = [];
    localStorage.removeItem('selectedStructures', JSON.stringify(this.selectedStructures));
  },

  setStructures() {
    if (!this.selectedStructures.length) {
      const storedStructures = JSON.parse(localStorage.getItem('selectedStructures'));
      if (storedStructures) {
        storedStructures.forEach((structure) => {
          store.addStructure(structure);
        });
      }
    }
  },

  setUserProfiles(profiles) {
    this.userProfiles = profiles;
    localStorage.setItem('userProfiles', JSON.stringify(profiles));
  },

  setChosenProfile(profile) {
    this.chosenProfile = profile;
    localStorage.setItem('chosenProfile', JSON.stringify(profile));
  },

  loadUserProfiles() {
    const profiles = JSON.parse(localStorage.getItem('userProfiles'));
    if (profiles) {
      this.userProfiles = profiles;
    }
  },

  loadChosenProfile() {
    const profile = JSON.parse(localStorage.getItem('chosenProfile'));
    if (profile) {
      this.chosenProfile = profile;
    }
  },

  // API endpoints methods

  async loginUser(email, password) {
    try {
      const response = await client.post('/api/login', { email, password });
      const { token } = response.data;
      await setAccessTokenCookie(token);
      return response.data; // Return the response from the API
    } catch (error) {
      console.log('Error during login:', error);
      if (error.response && error.response.data) {
        const { data } = error.response;
        if (data.errors) {
          const errorMessage = data.errors.email ? data.errors.email.join(' ') : 'Une erreur s\'est produite lors de la connexion.';
          throw new Error(errorMessage);
        } else if (data.message) {
          if (data.message === 'Email not verified') {
            await this.resendVerificationEmail(email);
            throw new Error('Email non vérifié. Un nouveau lien de vérification a été envoyé.');
          }
          throw new Error(data.message);
        }
      }
      throw new Error('Une erreur s\'est produite lors de la connexion.');
    }
  },

  async resendVerificationEmail(email) {
    console.log('Resending verification email...');
    try {
      const response = await client.post('/api/email/resend', { email });
      return response.data;
    } catch (error) {
      console.error('Error resending verification email:', error);
      throw error;
    }
  },

  async fetchUserProfiles() {
    try {
      const response = await client.get('/api/user-profiles');
      if (response.data.message === 'No profiles found') {
        this.setUserProfiles([]);
      } else {
        this.setUserProfiles(response.data);
      }
    } catch (error) {
      console.error('Error fetching user profiles:', error);
    }
  },

  async refreshUserProfiles() {
    try {
      const currentChosenProfile = this.chosenProfile;

      const response = await client.get('/api/user-profiles');
      if (response.data.message === 'No profiles found') {
        this.setUserProfiles([]);
      } else {
        const profiles = response.data;
        this.setUserProfiles(profiles);

        if (currentChosenProfile) {
          const updatedChosenProfile = profiles.find((profile) => currentChosenProfile && profile.id === currentChosenProfile.id);
          if (updatedChosenProfile) {
            this.setChosenProfile(updatedChosenProfile);
          }
        }
      }
    } catch (error) {
      console.error('Error refreshing user profiles:', error);
    }
  },

  async refreshUserProfilesAndSetLatest() {
    try {
      const response = await client.get('/api/user-profiles');
      if (response.data.message === 'No profiles found') {
        this.setUserProfiles([]);
      } else {
        const profiles = response.data;
        this.setUserProfiles(profiles);
        this.setLatestProfileAsChosen(profiles);
      }
    } catch (error) {
      console.error('Error refreshing user profiles:', error);
    }
  },

  setLatestProfileAsChosen(profiles) {
    if (profiles.length > 0) {
      const latestProfile = profiles.reduce((latest, profile) => (new Date(profile.created_at) > new Date(latest.created_at) ? profile : latest));
      this.setChosenProfile(latestProfile);
    }
  },

  async logoutUser() {
    try {
      await client.post('/api/logout');
    } catch (error) {
      console.error('Error during logout:', error);
    } finally {
      Cookies.remove('token');
      localStorage.removeItem('userProfiles');
      localStorage.removeItem('chosenProfile');
      this.userProfiles = [];
      this.chosenProfile = null;
      this.clearStructures();
    }
  },

  async resetPassword(email) {
    try {
      const response = await client.post('/api/password-reset', { email });
      return response.data;
    } catch (error) {
      console.error('Error resetting password:', error);
      throw error;
    }
  },
});

export default store;
