<template>
  <AddDocument v-if="displayEditFile" @close="displayEditFile = false" :type="typeSelected" :document="documentSelected"
    @confirm="$emit('update'); $emit('close');" :key="componentKey" />
  <div class="max-w-[1020px] mx-auto">
    <h2 class="mb-10 text-indigo-600 text-3xl">
      {{ documentSelected?.name }}</h2>
    <div class="">
      <div class="mt-6 border-t border-gray-100 mb-5">
        <dl class="divide-y divide-gray-100">
          <div class="pr-4 py-2 md:flex">
            <dt class="text-sm font-medium leading-5 text-gray-900 w-60">Créé à</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0" v-if="documentSelected?.created_at">
              {{ fetchEventServices.formatDate(new Date(documentSelected?.created_at)) }}
            </dd>
          </div>
          <div class="pr-4 py-2 md:flex items-center">
            <dt class="text-sm font-medium leading-5 text-gray-900 w-60">Partagé avec les personnnes</dt>
            <dd class="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <div>
                <UsersRelated type="document" :item="documentSelected?.id" :key="componentKey"
                  v-if="documentSelected" />
              </div>

            </dd>
          </div>
        </dl>
      </div>
      <p v-html="documentSelected?.description" class="mt-10"></p>

      <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200 mt-20">
        <li v-for="file in documentSelected?.files" :key="file.id"
          class="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6">
          <div class="flex w-0 flex-1 items-center">
            <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            <div class="ml-4 flex min-w-0 flex-1 gap-2">
              <span class="truncate font-medium">{{ file.file_path }}</span>
            </div>
          </div>
          <div class="ml-4 flex-shrink-0">
            <a :href="file.file_path" target="_blank" @click.prevent="downloadFile(file.id)"
              class="font-medium text-indigo-600 hover:text-indigo-500">Télécharger</a>
          </div>
        </li>
      </ul>
      <button @click.prevent="editFile('edition')" class="btn-primary mt-10 !text-lg">Modifier</button>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted, inject } from 'vue';
import { PaperClipIcon } from '@heroicons/vue/20/solid';
import AddDocument from '@/components/files/AddDocument.vue';
import UsersRelated from '@/components/files/UsersRelated.vue';
import fetchEventServices from '@/services/EventServices.js';
import client from '@/api/client';

const displayEditFile = ref(false);
let typeSelected = ref(null);
let componentKey = ref(0);
let documentSelected = ref(null);

const props = defineProps({
  document: [Object, Array],
});
const emit = defineEmits(['update', 'close']);

const editFile = (type) => {
  typeSelected.value = type;
  displayEditFile.value = true;
  componentKey.value += 1;
}
const downloadFile = async (fileId) => {

  try {
    client.get(`api/document-files/${fileId}/download`, {
      responseType: 'blob',
    }).then((response) => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      console.log('fileURL', response.data);

      const contentDisposition = response.headers['content-disposition'];
      let fileName = 'downloaded_file';

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length > 1) {
          fileName = fileNameMatch[1];
        }
      }

      fileLink.setAttribute('download', fileName);
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  } catch (error) {
    console.error('Failed to download document:', error);
  }
}
onMounted(() => {
  documentSelected.value = props.document;
  componentKey.value += 1;
});
</script>
