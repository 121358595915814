<template>
    <div>
        <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
            :detail="notificationMessage" @close="showNotification = false; errorMessage = '';" />
        <div class="md:flex md:items-center md:justify-between px-6 sm:px-10">
            <div class="min-w-0 flex-1">
                <div class="hero-structures max-w-[1520px] mx-auto">
                    <h1 class="title1 text-5xl mb-10 !mt-20 text-white"><span
                            v-if="context === 'profileCreation'">Créer</span><span v-else>Modifier</span>
                        un profil</h1>
                    <span v-if="formProfile.role"
                        class="mb-10 inline-flex items-center rounded-full bg-indigo-50 px-2 py-1 text-md font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10">{{
            formProfile.role.name }}</span>

                </div>

            </div>
        </div>
        <div class="mx-auto px-4 sm:px-6 lg:px-8 max-w-[1520px] bg-white pt-8">
            <nav aria-label="Progress" class="mb-20 hidden md:block">
                <ol role="list" class="space-y-4 md:flex md:space-x-8 md:space-y-0">
                    <li v-for="step in steps" :key="step.name" class="md:flex-1">
                        <a v-if="step.status === 'complete'" :href="step.href"
                            class="group flex flex-col border-l-4 border-indigo-600 py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-b-4 md:pb-0 md:pl-0 md:pb-4">
                            <span class="text-sm font-medium text-indigo-600 group-hover:text-indigo-800">{{ step.id
                                }}</span>
                            <span class="text-sm font-medium">{{ step.name }}</span>
                        </a>
                        <a v-else-if="step.status === 'current'" :href="step.href"
                            class="flex flex-col border-l-4 border-indigo-600 py-2 pl-4 md:border-l-0 md:border-b-4 md:pb-0 md:pl-0 md:pb-4"
                            aria-current="step">
                            <span class="text-sm font-medium text-indigo-600">{{ step.id }}</span>
                            <span class="text-sm font-medium">{{ step.name }}</span>
                        </a>
                        <a v-else :href="step.href"
                            class="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-b-4 md:pb-0 md:pl-0 md:pb-4">
                            <span class="text-sm font-medium text-gray-500 group-hover:text-gray-700">{{ step.id
                                }}</span>
                            <span class="text-sm font-medium">{{ step.name }}</span>
                        </a>
                    </li>
                </ol>
            </nav>
            <div class="space-y-10 divide-y divide-gray-900/10">
                <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3" id="infos">
                    <div class="px-4 sm:px-0">
                        <p class="text-indigo-600 text-6xl mb-4">01</p>
                        <h2 class="text-base font-semibold leading-7 text-gray-900">{{ steps[0].title }}</h2>
                        <p class="mt-1 text-md leading-6 text-gray-600">{{ steps[0].description }}</p>
                    </div>
                    <form class=" md:col-span-2" v-if="!profileId || canEditRole">
                        <div class="bg-indigo-100 px-4 py-6 rounded-lg mb-5">
                            <Listbox as="div" v-model="formProfile.role">
                                <ListboxLabel class="block text-md font-medium leading-6 text-gray-900">Rôle<sup
                                        class="text-red-500 font-semibold text-base">*</sup>
                                </ListboxLabel>
                                <div class="relative mt-2">
                                    <ListboxButton
                                        class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-md sm:leading-6">
                                        <span class="block truncate" v-if="formProfile.role">{{ formProfile.role.name
                                            }}</span><span v-else>Choisissez
                                            un
                                            rôle</span>
                                        <span
                                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                    </ListboxButton>

                                    <transition leave-active-class="transition ease-in duration-100"
                                        leave-from-class="opacity-100" leave-to-class="opacity-0">
                                        <ListboxOptions
                                            class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-md">
                                            <ListboxOption as="template" v-for="availableRole in availableRoles"
                                                :key="availableRole.id" :value="availableRole"
                                                v-slot="{ active, selected }">
                                                <li
                                                    :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                                    <span
                                                        :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">{{
            availableRole.name }}</span>
                                                    <span v-if="selected"
                                                        :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                </li>
                                            </ListboxOption>
                                        </ListboxOptions>
                                    </transition>
                                </div>
                            </Listbox>
                        </div>
                    </form>
                    <div v-else class="md:col-span-2">
                        <div
                            class="w-100 mr-auto p-6 rounded-lg bg-indigo-100 text-center text-xl flex items-center place-content-center">
                            <UserIcon class="w-8 h-8 mr-3" />{{
            currentProfile.role?.name }}
                        </div>
                    </div>
                </div>
                <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3" id="infos">
                    <div class="px-4 sm:px-0">
                        <p class="text-indigo-600 text-6xl mb-4">02</p>
                        <h2 class="text-base font-semibold leading-7 text-gray-900">{{ steps[1].title }}</h2>
                        <p class="mt-1 text-md leading-6 text-gray-600">{{ steps[1].description }}</p>
                    </div>

                    <form class=" md:col-span-2">
                        <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl">
                            <div class="px-4 py-6 sm:p-8">
                                <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div class="col-span-full" v-if="formProfile.role">
                                        <div v-if="canHaveStructures">
                                            <Combobox as="div" v-model="formProfile.structures" required
                                                @update:modelValue="query = ''" multiple>
                                                <ComboboxLabel
                                                    class="block text-sm font-medium leading-6 text-gray-900">
                                                    Choisissez une structure
                                                </ComboboxLabel>
                                                <div class="my-3">
                                                    <button v-for="structure in formProfile.structures"
                                                        :key="structure.id" type="button"
                                                        class="mr-2 inline-flex items-center gap-x-1.5 rounded-full bg-indigo-100 px-2.5 py-1.5 text-sm font-normal text-indigo-900 shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                                        {{ structure.name }}
                                                        <XCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true"
                                                            @click="removeStructure(structure.id)" />
                                                    </button>
                                                </div>
                                                <div class="relative mt-2">
                                                    <ComboboxInput
                                                        class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                        @change="query = $event.target.value; searchStructures($event.target.value)"
                                                        @blur="query = ''"
                                                        placeholder="Écrivez les premières lettres de la structure"
                                                        :display-value="(structure) => structure?.name" />
                                                    <ComboboxButton
                                                        class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                        <ChevronUpDownIcon class="h-5 w-5 text-gray-400"
                                                            aria-hidden="true" />
                                                    </ComboboxButton>

                                                    <ComboboxOptions
                                                        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        <ComboboxOption v-for="structure in relevantStructures"
                                                            :key="structure.id" :value="structure" as="template"
                                                            v-slot="{ active, selected }">
                                                            <li
                                                                :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                                                                <span
                                                                    :class="['block truncate', selected && 'font-semibold']">
                                                                    {{ structure.name }}
                                                                </span>

                                                                <span v-if="selected"
                                                                    :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                                                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            </li>
                                                        </ComboboxOption>
                                                    </ComboboxOptions>
                                                </div>
                                            </Combobox>
                                        </div>
                                    </div>
                                    <div class="col-span-full" v-if="formProfile.role">
                                        <div v-if="formProfile.role.name !== 'Apprenant·e'">
                                            <div v-if="canHaveStructures">
                                                <Combobox as="div" v-model="formProfile.organization" required
                                                    @update:modelValue="query = ''">
                                                    <ComboboxLabel
                                                        class="block text-sm font-medium leading-6 text-gray-900">
                                                        Organisation
                                                        associée
                                                    </ComboboxLabel>
                                                    <div class="relative mt-2">
                                                        <ComboboxInput
                                                            class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                            @change="query = $event.target.value" @blur="query = ''"
                                                            :display-value="(organization) => organization?.name" />
                                                        <ComboboxButton
                                                            class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                                            <ChevronUpDownIcon class="h-5 w-5 text-gray-400"
                                                                aria-hidden="true" />
                                                        </ComboboxButton>

                                                        <ComboboxOptions v-if="filteredOrganizations.length > 0"
                                                            class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                            <ComboboxOption
                                                                v-for="organization in filteredOrganizations"
                                                                :key="organization.id" :value="organization"
                                                                as="template" v-slot="{ active, selected }">
                                                                <li
                                                                    :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-indigo-600 text-white' : 'text-gray-900']">
                                                                    <span
                                                                        :class="['block truncate', selected && 'font-semibold']">
                                                                        {{ organization.name }}
                                                                    </span>

                                                                    <span v-if="selected"
                                                                        :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-indigo-600']">
                                                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                                                    </span>
                                                                </li>
                                                            </ComboboxOption>
                                                        </ComboboxOptions>
                                                    </div>
                                                </Combobox>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-full">
                                        <label for="photo"
                                            class="block text-md font-medium leading-6 text-gray-900">Photos</label>
                                        <div class="mt-2 flex items-center gap-x-3">
                                            <div v-if="formProfile.profile_picture_url">
                                                <img class="h-20 w-20 rounded-full object-cover"
                                                    :src="formProfile.profile_picture_url" alt="" />
                                            </div>
                                            <UserCircleIcon class="h-12 w-12 text-gray-300" aria-hidden="true" v-else />

                                            <input id="fileInput" type="file" @change="selectFile($event)"
                                                accept="image/png, image/jpeg, image/jpg" placeholder="image"
                                                class="rounded-md bg-white px-2.5 py-1.5 text-md text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" />
                                        </div>
                                    </div>
                                    <div class="col-span-full">

                                        <div class="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                                            <div v-for=" genre  in  humanGenres " :key="genre.id"
                                                class="flex items-center">
                                                <input :id="genre.id" name="genre" type="radio"
                                                    v-model="formProfile.genre_id"
                                                    :checked="genre.id === formProfile.genre_id" :value="genre.id"
                                                    class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                                                <label :for="genre.id"
                                                    class="ml-3 block text-sm font-medium leading-6 text-gray-900">{{
            genre.title }}<sup
                                                        class="text-red-500 font-semibold text-base">*</sup></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-span-full">
                                        <label for="name"
                                            class="block text-md font-medium leading-6 text-gray-900">Téléphone</label>
                                        <div class="mt-2">
                                            <input type="text" name="phone_number" id="phone_number"
                                                autocomplete="phone_number" required="required"
                                                v-model="formProfile.phone_number"
                                                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                        </div>
                                    </div>
                                    <div class="col-span-full">
                                        <label for="name"
                                            class="block text-md font-medium leading-6 text-gray-900">Adresse</label>
                                        <div class="mt-2">
                                            <input type="text" name="address" id="address" autocomplete="address"
                                                required="required" v-model="formProfile.address"
                                                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                        </div>
                                    </div>
                                    <div class="sm:col-span-3">
                                        <label for="postal-code"
                                            class="block text-md font-medium leading-6 text-gray-900">Code postal
                                        </label>
                                        <div class="mt-2">
                                            <input id="zip_code" name="zip_code" type="text"
                                                v-model="formProfile.zip_code" autocomplete="zip_code"
                                                required="required"
                                                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                        </div>
                                    </div>
                                    <div class="sm:col-span-3">
                                        <label for="name"
                                            class="block text-md font-medium leading-6 text-gray-900">Ville</label>
                                        <div class="mt-2">
                                            <input type="text" name="city" id="city" autocomplete="city"
                                                required="required" v-model="formProfile.city"
                                                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                        </div>
                                    </div>

                                </div>
                                <div class="col-span-full">
                                    <AlertMessage v-if="errorMessage" type="error" :content="errorMessage" />
                                </div>
                            </div>

                            <div
                                class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                                <button type="button" class="text-md font-semibold leading-6 text-gray-900">
                                    Annuler
                                </button>
                                <button v-if="context === 'profileCreation'" class="rounded-md bg-indigo-600 px-3 py-2 text-md font-semibold text-white shadow-sm
                                hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2
                                focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    @click.prevent="createProfile">
                                    Créer
                                </button>
                                <button v-else class="rounded-md bg-indigo-600 px-3 py-2 text-md font-semibold text-white shadow-sm
                                hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2
                                focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    @click.prevent="modifyProfile">
                                    Enregistrer
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3" id="presentation">
                    <div class="px-4 sm:px-0">
                        <p class="text-indigo-600 text-6xl mb-4">03</p>
                        <h2 class="text-base font-semibold leading-7 text-gray-900">{{ steps[2].title }}</h2>
                        <p class="mt-1 text-md leading-6 text-gray-600">{{ steps[2].description }}</p>
                    </div>
                    <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
                        v-if="formProfile.role">
                        <div class="px-4 py-6 sm:p-8">
                            <div class="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                <div class="col-span-full">
                                    <label for="street-address"
                                        class="block text-md font-medium leading-6 text-gray-900">Qui je suis
                                        professionnellement</label>
                                    <div class="mt-2">
                                        <textarea rows="10" name="bio" id="bio" v-model="formProfile.bio"
                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                    </div>
                                </div>
                                <div class="col-span-full">
                                    <label for="street-address"
                                        class="block text-md font-medium leading-6 text-gray-900">Ce qui
                                        m’intéresse
                                        dans le fait d’être un {{ formProfile.role.name }}</label>
                                    <div class="mt-2">
                                        <textarea rows="10" name="personal_approach" id="personal_approach"
                                            v-model="formProfile.personal_approach"
                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                    </div>
                                </div>
                                <div class="col-span-full">
                                    <label for="street-address"
                                        class="block text-md font-medium leading-6 text-gray-900">Si je suis
                                        votre {{
            formProfile.role.name }}, il faut que vous sachiez que… </label>
                                    <div class="mt-2">
                                        <textarea rows="10" name="personal_presentation" id="personal_presentation"
                                            v-model="formProfile.personal_presentation"
                                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6" />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div
                            class="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                            <button type="button" class="text-md font-semibold leading-6 text-gray-900">
                                Annuler
                            </button>
                            <button v-if="context === 'profileCreation'" class="rounded-md bg-indigo-600 px-3 py-2 text-md font-semibold text-white shadow-sm
                                hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2
                                focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                @click.prevent="createProfile">
                                Créer
                            </button>
                            <button v-else class="rounded-md bg-indigo-600 px-3 py-2 text-md font-semibold text-white shadow-sm
                                hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2
                                focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                @click.prevent="modifyProfile">
                                Enregistrer
                            </button>
                        </div>

                    </form>

                </div>
            </div>
            <div class="w-full pt-5 flex justify-end mt-10" v-if="profileId">

                <router-link :to="'/utilisateurs/profil/' + profileId" class="ml-auto btn-read mr-2"
                    target="_blank"><button>
                        Voir la page publique
                    </button></router-link>
                <button class="  rounded-md bg-emerald-400 px-3 py-2 text-md font-medium text-white shadow-sm
                                hover:bg-emerald-300 focus-visible:outline focus-visible:outline-2
                                focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    @click.prevent="modifyProfile('end')">
                    Terminer
                </button>

            </div>
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, computed, onMounted, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { UserIcon } from '@heroicons/vue/24/outline';
import { UserCircleIcon } from '@heroicons/vue/24/solid';
import { CheckIcon, ChevronUpDownIcon, XCircleIcon } from '@heroicons/vue/20/solid';
import {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxLabel,
    ComboboxOption,
    ComboboxOptions,
} from '@headlessui/vue';
import {
    Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions
} from '@headlessui/vue';
import AlertMessage from '@/components/alert/AlertMessage.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import client from '@/api/client';
import { debounce } from 'lodash';

const route = useRoute();
const router = useRouter();
const store = inject('store');
const currentProfile = store.chosenProfile;
let profileId = ref(route.params.id);

const props = defineProps({
    context: {
        String,
        default: 'default'
    },
});

const showNotification = ref(false);
const notificationType = ref('success');
const errorMessage = ref('');
const notificationTitle = ref('');
const notificationMessage = ref('');
const selectedPhoto = ref(null);

const steps = [
    { id: 'Step 1', name: 'Mon rôle', href: '#infos', status: 'upcoming', title: 'Je précise mon rôle', description: 'je renseigne ici le rôle' },
    { id: 'Step 2', name: 'Mon profil', href: '#infos', status: 'upcoming', title: 'Je précise mon profil', description: 'je renseigne ici les informations générales de localisation et contact sur la structure' },
    { id: 'Step 3', name: 'Présentation', href: '#presentation', status: 'upcoming', title: 'Je me présente', description: 'Je présente ici de la structure, ses activités, ses publics, ses spécificités, etc.' },
]
const canEditRole = computed(() => (currentProfile?.role.name === 'Coordinateur·trice') || (currentProfile?.role.name === 'Assitant·e de formation') || (currentProfile?.role.name === 'Administrateur·trice'));
// Todo : check if the user can need to select structures at this point
const canHaveStructures = computed(() => (formProfile.value.role.name === 'Formateur·trice métier'));

const availableRoles = ref([]);
const formProfile = ref({
    role: null,
    organization: [],
    genre_id: null,
});
const query = ref('');
const relevantStructures = ref([]);
const availableOrganizations = ref([]);
const isOrganizationFilled = computed(() => isFormFilled(formProfile.value.organization.length > 0));
const humanGenres = [
    { id: '1', title: 'Madame' },
    { id: '2', title: 'Monsieur' },
    { id: '3', title: 'Neutre' },
];

const formImg = ref({
    document: null,
});

const searchStructures = debounce(async (keyword) => {
    try {
        const response = await client.get(`/api/structures/search/search-by-name?query=${keyword}`);
        relevantStructures.value = response.data;
    } catch (error) {
        console.error('Failed to fetch structures details:', error);
    }
}, 600);

const fetchRoles = async () => {
    try {
        const response = await client.get('/api/roles/profile-creation');
        availableRoles.value = response.data;
    } catch (error) {
        errorMessage.value = 'Erreur lors de la récupération des rôles. ';
        if (error.response) {
            errorMessage.value += error.response.data.message;
        }
    }
}

const fetchOrganizations = async () => {
    try {
        const response = await client.get(`/api/organizations`);
        availableOrganizations.value = response.data;
    } catch (error) {
        console.error('Failed to fetch organizations details:', error);
        errorMessage.value += error.response.data.message;
    }
};

const fetchProfileDetails = async (id) => {
    try {
        const response = await client.get(`/api/profiles/${id}`);
        formProfile.value = response.data;
    } catch (error) {
    }
};

const filteredOrganizations = computed(() =>
    query.value === ''
        ? availableOrganizations.value
        : availableOrganizations.value.filter((organization) => {
            return organization.name.toLowerCase().includes(query.value.toLowerCase())
        })
);

const removeStructure = async (structure) => {
    console.log('structure', structure);
    if (profileId) {
        const idStructures = [Number(structure)];
        try {
            const response = await client.post(`/api/profiles/${profileId}/remove-structures`, {
                structure_ids: idStructures,
            });
            store.fetchUserProfiles();
            fetchProfileDetails(profileId);
        } catch (error) {
            errorMessage.value = 'Erreur lors de l\'association d\'une strcuture';
            notificationType.value = 'error';
            showNotification.value = true;
            notificationTitle.value = 'Erreur lors de l\'enregistrement';
            if (error.response) {
                errorMessage.value += error.response.data.message;
                notificationMessage.value = error.response.data.message;
            }
        }
    } else {
        const index = formProfile.value.structures.findIndex((selectedStructure) => selectedStructure.id === structure);
        formProfile.value.structures.splice(index, 1);
    }
}

const addStructures = async (idProfile) => {
    const structuresToAssociate = formProfile.value.structures.map((structure) => structure.id);
    try {
        const response = await client.post(`/api/profiles/${idProfile}/add-structures`, {
            structure_ids: structuresToAssociate,
        });
        store.refreshUserProfiles();
        router.push({ name: 'Modifier un profil', params: { id: response.data.id } });
    } catch (error) {
        errorMessage.value = 'Erreur lors de l\'association d\'une strcuture';
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
}

const createProfile = async () => {
    let userId = null;
    if (currentProfile) {
        userId = currentProfile.user.id;
    }
    if (!formProfile.value.role) {
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Le champ "rôle" n\'est pas renseigné';
        notificationMessage.value = 'Veuillez remplir le rôle. Ce champ est obligatoire.';
    } else {
        try {
            const response = await client.post('/api/profiles', {
                role_id: formProfile.value.role.id,
                genre_id: Number(formProfile.value.genre_id),
                bio: formProfile.value.bio,
                personal_presentation: formProfile.value.personal_presentation,
                personal_approach: formProfile.value.personal_approach,
                organization_id: formProfile.value.organization.id,
                phone_number: formProfile.value.phone_number,
                address: formProfile.value.address,
                city: formProfile.value.city,
                zip_code: formProfile.value.zip_code,
            });
            showNotification.value = true;
            setTimeout(() => { showNotification.value = false; }, 3000);
            notificationType.value = 'success';
            notificationTitle.value = 'Bravo, le profil a bien été enregistré';
            notificationMessage.value = 'Vous pouvez encore le modifier.';
            store.refreshUserProfilesAndSetLatest();
            if (selectedPhoto.value) {
                uploadFile(response.data.id);
            }
            if (formProfile.value.structures) {
                addStructures(response.data.id);
            } else {
                router.push({ name: 'Modifier un profil', params: { id: response.data.id } });
            }

        } catch (error) {
            errorMessage.value = 'Erreur lors de la création du profil. ';
            notificationType.value = 'error';
            showNotification.value = true;
            notificationTitle.value = 'Erreur lors de l\'enregistrement';
            if (error.response) {
                errorMessage.value += error.response.data.message;
                notificationMessage.value = error.response.data.message;
            }
        }
    }
}

const modifyProfile = async (type) => {
    let organisationId = null;
    if (formProfile.value.organization) {
        organisationId = formProfile.value.organization.id;
    }

    try {
        const response = await client.put(`/api/profiles/${profileId}`, {
            role_id: formProfile.value.role.id,
            genre_id: formProfile.value.genre_id,
            bio: formProfile.value.bio,
            personal_presentation: formProfile.value.personal_presentation,
            personal_approach: formProfile.value.personal_approach,
            organization_id: organisationId,
            phone_number: formProfile.value.phone_number,
            address: formProfile.value.address,
            city: formProfile.value.city,
            zip_code: formProfile.value.zip_code,
        });
        store.fetchUserProfiles();
        showNotification.value = true;
        setTimeout(() => { showNotification.value = false; }, 3000);
        notificationType.value = 'success';
        notificationTitle.value = 'Bravo, le profil a bien été enregistré';
        notificationMessage.value = 'Vous pouvez encore le modifier.';
        if (!canEditRole) {
            store.setLatestProfileAsChosen(store.userProfiles);
        }
        if (formProfile.value.structures.length > 0) {
            addStructures(response.data.id);
        } else if (type === 'end') {
            router.push({ name: 'Tableau de bord', params: { id: response.data.id } });
        } else {
            router.push({ name: 'Modifier un profil', params: { id: response.data.id } });
        }

    } catch (error) {
        errorMessage.value = 'Erreur lors de la création du profil. ';
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
}

const selectFile = (event) => {
    const file = event.target.files[0];
    if (file) {
        selectedPhoto.value = file;
        if (profileId) {
            uploadFile(profileId);
        };
        // Todo: add upload File when creating a profile
    }
}

const uploadFile = async (idProfile) => {
    const formDataProfile = new FormData();
    formDataProfile.append('profile_picture', selectedPhoto.value);
    try {
        const response = await client.post(`/api/profiles/${idProfile}`, formDataProfile, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            params: {
                _method: "put",
            },
        });
        store.refreshUserProfiles();
        if (profileId) {
            fetchProfileDetails(profileId);
        }

    } catch (error) {
        errorMessage.value = 'Erreur lors de la création du profil. ';
        notificationType.value = 'error';
        showNotification.value = true;
        notificationTitle.value = 'Erreur lors de l\'enregistrement';
        if (error.response) {
            errorMessage.value += error.response.data.message;
            notificationMessage.value = error.response.data.message;
        }
    }
}

onMounted(() => {
    store.refreshUserProfiles();
    fetchOrganizations();
    fetchRoles();
    profileId = route.params.id;
    if (profileId) {
        fetchProfileDetails(profileId);
    }
});
</script>
