<template>
  <AppLayout>
    <div class="bg-white rounded-3xl pt-5 max-w-[1400px] mx-auto  px-4 sm:px-6 lg:px-10"
      style="min-height: calc( 100vh - 400px );">
      <div class="mx-auto">
        <ProfileHeader />
      </div>
      <div class="bg-white" v-if="currentProfile" style="min-height: calc( 100vh - 500px );">
        <div class=""
          v-if="currentProfile.role.name === 'Administrateur·trice' || currentProfile.role.name === ('Coordinateur·trice') || currentProfile.role.name === 'Assistante de formation' || currentProfile.role.name === 'Formateur·trice référent·e'">
          <div class="">
            <NavBarAdmin v-model="componentToDisplay" />
          </div>
          <div class=" flex-1 pt-10">
            <AdminDashboardAdministrator v-model="componentToDisplay" v-if="componentToDisplay === 'account'" />
            <AdminStructuresList v-if="componentToDisplay === 'structures'" />
            <AdminOrganizationsList v-if="componentToDisplay === 'organizations'" />
            <AdminUsersList v-if="componentToDisplay === 'users'" />
            <AdminLegalFormList v-if="componentToDisplay === 'legalForms'" />
            <AdminTypesList v-if="componentToDisplay === 'types'" />
            <AdminPublicsList v-if="componentToDisplay === 'publics'" />
            <AdminWishlistList v-if="componentToDisplay === 'wishlists'" />
            <AdminDegreesList v-if="componentToDisplay === 'degrees'" />
            <AdminTrainingActions v-if="componentToDisplay === 'training-actions'" />
            <AdminEvents v-if="componentToDisplay === 'events'" />
          </div>
        </div>
        <div v-if="currentProfile.role.name === 'Apprenant·e'" class="bg-white py-5">
          <HomeStudent />
          <div class="mt-10">
          </div>
        </div>

        <div v-if="currentProfile.role.name === 'Formateur·trice métier'" class="bg-white py-10">
          <HomeTutor />
          <FilesManager v-if="componentToDisplay === 'documents'" />
        </div>
      </div>
    </div>

  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import {
  inject, onMounted, watch, ref,
} from 'vue';
import AppLayout from '@/layouts/AppLayout.vue';
import ProfileHeader from '@/components/profiles/ProfileHeader.vue';
import { useRouter } from 'vue-router';
import NavBarAdmin from '@/components/app/NavBarAdmin.vue';
import AdminStructuresList from '@/components/admin/AdminStructuresList.vue';
import AdminOrganizationsList from '@/components/admin/AdminOrganizationsList.vue';
import AdminUsersList from '@/components/admin/AdminUsersList.vue';
import AdminLegalFormList from '@/components/admin/AdminLegalFormList.vue';
import AdminTypesList from '@/components/admin/AdminTypesList.vue';
import AdminPublicsList from '@/components/admin/AdminPublicsList.vue';
import AdminWishlistList from '@/components/admin/AdminWishlistList.vue';
import AdminDegreesList from '@/components/admin/AdminDegreesList.vue';
import AdminDashboardAdministrator from '@/components/dashboard/AdminDashboardAdministrator.vue';
import AdminDashboardCoordinator from '@/components/dashboard/AdminDashboardCoordinator.vue';
import AdminTrainingActions from '@/components/admin/AdminTrainingActions.vue';
import HomeTutor from '@/components/dashboard/HomeTutor.vue';
import AdminEvents from '@/components/admin/AdminEvents.vue';
import HomeStudent from '@/components/dashboard/HomeStudent.vue';
import FilesManager from '@/components/files/FilesManager.vue';

const store = inject('store');
const router = useRouter();
const currentProfile = ref(store.chosenProfile);
const componentToDisplay = ref('account');

watch(() => store.chosenProfile, (newProfile) => {
  currentProfile.value = newProfile;
});

onMounted(() => {
  store.refreshUserProfiles();
  if (store.userProfiles.length === 0) {
    router.push({ name: 'Créer un profil' });
  }
});
</script>
