<template>
    <div class="">
        <MilestonesTimeline v-if="trainingOngoing" />
        <CurrentTrainingActions v-else />
        <div class="mt-10">
            <NewDocuments />
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, inject, watch, computed, onMounted } from 'vue';
import CurrentTrainingActions from '@/components/training-actions/CurrentTrainingActions.vue';
import MilestonesTimeline from '@/components/milestones/MilestonesTimeline.vue';
import NewDocuments from '@/components/files/NewDocuments.vue';
import client from '@/api/client';

const store = inject('store');
const currentProfile = ref(store.chosenProfile);
const profileId = store.chosenProfile.id;
const currentTrainingActions = ref([]);
const trainingOngoing = ref(false);
const componentKey = ref(0);
const currentAction = ref(null);

watch(() => store.chosenProfile, (newProfile) => {
    currentProfile.value = newProfile;
});

const fetchCurrentTrainingActions = async () => {
    const response = await client.get(`/api/training-actions/profile/${profileId}`);
    currentTrainingActions.value = response.data;
    if (currentTrainingActions.value.length !== 0) {
        if (currentTrainingActions.value[0].is_ongoing) {
            trainingOngoing.value = true;
            currentAction.value = currentTrainingActions.value[0];
            componentKey.value += 1;
        }
    }
};

onMounted(async () => {
    fetchCurrentTrainingActions();
});
</script>
