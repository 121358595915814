<template>
    <AppLayout>
        <div class="md:flex md:items-center md:justify-between px-6 sm:px-10">
            <div class="min-w-0 flex items-center w-full max-w-[1520px] mx-auto">
                <div class="hero-structures">
                    <h1 class="title1 text-5xl mb-5 !mt-20 text-white"><span>Organiser</span>
                        un événement</h1>
                    <h2 class="text-2xl text-indigo-600 mb-10 text-white">
                        {{ relevantEvent.name }}
                        <p>du {{ startDate }}
                            au {{ endDate }}</p>
                    </h2>
                </div>
                <div class="ml-auto"><button @click.prevent="exportWhishlists"
                        class="btn-primary !bg-cyan-300 !text-cyan-800">Exporter
                        les
                        voeux</button></div>
            </div>
        </div>
        <div class="mx-auto px-4 sm:px-6 lg:px-8 max-w-[1520px] bg-white pt-8 rounded-xl">
            <nav aria-label="Progress" class="mb-20 px-20 pt-10">
                <ol role="list" class="space-y-4 md:flex md:space-x-8 md:space-y-0">
                    <li v-for="step in steps" :key="step.name" class="md:flex-1">
                        <a v-if="step.status === 'complete'" :href="step.href"
                            class="group flex flex-col border-l-4 border-indigo-600 py-2 pl-4 hover:border-indigo-800 md:border-l-0 md:border-b-4 md:pb-0 md:pl-0 md:pb-4">
                            <span class="text-sm font-medium text-indigo-600 group-hover:text-indigo-800">{{ step.id
                                }}</span>
                            <span class="text-sm font-medium">{{ step.name }}</span>
                        </a>
                        <a v-else-if="step.status === 'current'" :href="step.href"
                            class="flex flex-col border-l-4 border-indigo-600 py-2 pl-4 md:border-l-0 md:border-b-4 md:pb-0 md:pl-0 md:pb-4"
                            aria-current="step">
                            <span class="text-sm font-medium text-indigo-600">{{ step.id }}</span>
                            <span class="text-sm font-medium">{{ step.name }}</span>
                        </a>
                        <a v-else :href="step.href"
                            class="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-b-4 md:pb-0 md:pl-0 md:pb-4">
                            <span class="text-sm font-medium text-gray-500 group-hover:text-gray-700">{{ step.id
                                }}</span>
                            <span class="text-sm font-medium">{{ step.name }}</span>
                        </a>
                    </li>
                </ol>
            </nav>
            <div class="mb-10  px-20">
                <p>{{ relevantEvent.description }}</p>
                <p>{{ relevantEvent.address }}</p>
                <p>{{ relevantEvent.zip_code }}{{ relevantEvent.city }}</p>
            </div>
            <section id="zones">
                <AdminZones v-model="relevantEvent" />
            </section>
            <section id="timeslots" class="mt-20">
                <AdminTimeslots v-model="relevantEvent" />
            </section>
            <section id="participations" class="mt-20">
                <AdminParticipations v-model="relevantEvent" />
            </section>
            <section class="mx-auto 2xl:max-w-7xl detail-page !px-0 lg:px-8 mt-20">
                <div class="sm:flex sm:items-center">
                    <div class="sm:flex-auto">
                        <h2 class="text-xl text-indigo-700 font-semibold leading-6 text-gray-900">Vœux
                            pré-événement</h2>
                        <p class="mt-2 text-sm text-gray-700">Liste des vœux enregistrés pour la rencontre</p>
                    </div>
                    <div><button @click.prevent="exportWhishlists" class="btn-primary">Exporter les voeux</button></div>
                </div>
                <div class="mt-8 flow-root">
                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table class="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th scope="col"
                                            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                            Apprenant
                                        </th>
                                        <th scope="col"
                                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Structures sélectionnées
                                        </th>
                                        <th scope="col"
                                            class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                            Détail
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr v-for=" relevantWhishlist in relevantWhishlists" :key="relevantWhishlist?.id"
                                        class="even:bg-gray-50">
                                        <td class="px-3 pl-0 py-4 text-sm text-indigo-700 font-medium">{{
                            relevantWhishlist?.profile_name }}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm font-normal">
                                            <div v-for="profileWishlist in relevantWhishlist.wishlists" class="mb-1"
                                                :key="profileWishlist.id">
                                                <div v-if="profileWishlist?.is_pre_event === 1">
                                                    {{ profileWishlist.structure.name }}
                                                    <span
                                                        class="ml-2 inline-flex items-center rounded-full bg-green-50 px-2 py-0 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                                                        v-if="profileWishlist?.is_initial_choice === 1">prioritaire</span>

                                                </div>
                                            </div>
                                        </td>
                                        <td
                                            class="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium cursor-pointer">
                                            <router-link
                                                :to="'/apprenant-voeux/' + relevantWhishlist.profile_id + '/' + eventId">
                                                <button
                                                    class="text-indigo-600 hover:text-indigo-900">Voir</button></router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            <section class="mx-auto 2xl:max-w-7xl detail-page !px-0 lg:px-8 mt-20">
                <div class="sm:flex sm:items-center">
                    <div class="sm:flex-auto">
                        <h2 class="text-xl text-indigo-700 font-semibold leading-6 text-gray-900">Vœux post
                            événement</h2>
                        <p class="mt-2 text-sm text-gray-700">Liste des vœux définitifs</p>
                    </div>
                    <div><button @click.prevent="exportWhishlists" class="btn-primary">Exporter les voeux</button></div>
                </div>
                <div class="mt-8 flow-root">
                    <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <table class="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                        <th scope="col"
                                            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                            Apprenant
                                        </th>
                                        <th scope="col"
                                            class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Structures sélectionnées
                                        </th>
                                        <th scope="col"
                                            class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                                            Détail
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200 bg-white">
                                    <tr v-for=" relevantWhishlist in relevantWhishlists" :key="relevantWhishlist?.id"
                                        class="even:bg-gray-50">
                                        <td class="px-3 pl-0 py-4 text-sm text-indigo-700 font-medium">{{
                            relevantWhishlist?.profile_name }}</td>
                                        <td class="whitespace-nowrap px-3 py-4 text-sm font-normal">
                                            <div v-for="profileWishlist in relevantWhishlist.wishlists" class="mb-1"
                                                :key="profileWishlist.id">
                                                <div v-if="profileWishlist?.is_pre_event === 0">
                                                    {{ profileWishlist.structure.name }}
                                                    <span
                                                        class="ml-2 inline-flex items-center rounded-full bg-green-50 px-2 py-0 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"
                                                        v-if="profileWishlist?.is_initial_choice === 1">prioritaire</span>

                                                </div>
                                            </div>
                                        </td>
                                        <td
                                            class="relative whitespace-nowrap py-4 pl-3 pr-4 text-center text-sm font-medium cursor-pointer">
                                            <router-link
                                                :to="'/apprenant-voeux/' + relevantWhishlist.profile_id + '/' + eventId">
                                                <button
                                                    class="text-indigo-600 hover:text-indigo-900">Voir</button></router-link>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AppLayout from '@/layouts/AppLayout.vue';
import fetchEventServices from '@/services/EventServices.js';
import AdminZones from '@/components/events/AdminZones.vue';
import AdminParticipations from '@/components/events/AdminParticipations.vue';
import AdminTimeslots from '@/components/events/AdminTimeslots.vue';
import client from '@/api/client';

const steps = [
    { id: 'Zones', name: 'Éditer les zones', href: '#zones', status: 'upcoming', title: 'Informations générales sur la structure', description: 'Renseignez ici les informations générales de localisation et contact sur la structure' },
    { id: 'Créneaux', name: 'Éditer les créneaux "', href: '#time-slots', status: 'upcoming', title: 'Présentation de la structure', description: 'Présentez ici de la structure, ses activités, ses publics, ses spécificités, etc.' },
    { id: 'Structures associées', name: 'Associer une structure à une zone', href: '#participations', status: 'upcoming', title: 'Situation de travail', description: 'Décrivez ici la situation de travail de la structure' },
]
const route = useRoute();
const relevantEvent = ref([]);
const eventId = ref(route.params.id);
const startDate = ref(null);
const endDate = ref(null);
const relevantWhishlists = ref([]);

const exportWhishlists = async () => {
    console.log('exportWhishlists');
    try {
        client.get(`api/export-wishlists/event/${eventId.value}`, {
            responseType: 'blob',
        }).then((response) => {
            const fileURL = window.URL.createObjectURL(new Blob([response.data]));
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            const today = new Date();
            const todayFormatted = today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();
            fileLink.setAttribute('download', `voeux-${todayFormatted}.xlsx`);
            document.body.appendChild(fileLink);
            fileLink.click();
        });
    } catch (error) {
        console.error('Failed to export wishlist:', error);
    }
}

onMounted(async () => {
    try {
        const response = await client.get(`api/events/${eventId.value}/related`);
        relevantEvent.value = response.data.event;
        startDate.value = fetchEventServices.formatDate(new Date(relevantEvent.value.start_date));
        endDate.value = fetchEventServices.formatDate(new Date(relevantEvent.value.end_date));
        try {
            const response = await client.get(`/api/wishlists/event/${eventId.value}/group-by-profile`);
            relevantWhishlists.value = response.data;
        } catch (error) {
            console.error('Failed to fetch the next event:', error);
        }
    } catch (error) {
        console.error('Failed to fetch the next event:', error);
    }

});

</script>
