<template>
  <AppLayout>
    <div class="md:flex md:items-center md:justify-between px-6 sm:px-10">
      <div class="min-w-0 flex-1">
        <div class="hero-structures max-w-[1520px] mx-auto">
          <h1 class="title1 text-5xl mb-5 !mt-20 text-white"><span v-if="$route.params.idMilestone === '3'">Situation de
              travail 1</span><span v-else-if="$route.params.idMilestone === '4'">Bilan</span><span v-else>Rendez-vous
              {{ $route.params.idMilestone }}</span>
          </h1>
          <h2 class="text-2xl text-indigo-600 mb-10 text-white">
            12/09/2024 9:00
          </h2>
        </div>
      </div>
    </div>
    <div class="mx-auto px-4 sm:px-6 lg:px-8 max-w-[1520px] bg-white pt-8 rounded-xl">
      <div class="mx-auto max-w-[1100px] mt-20">
        <div class="mb-10" id="intro">
          <div class="px-4 sm:px-0">
            <h3 class="text-xl font-semibold leading-7 text-indigo-700">Présentation</h3>
            <p class="mt-1 max-w-2xl text-base leading-7 text-gray-500">Présentation de l'étape et ses enjeux</p>
          </div>
          <div class="mt-6 border-t border-gray-100">
            <dl class="divide-y divide-gray-100">
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-base font-medium leading-7 text-gray-900">Rôles attendus</dt>
                <dd class="mt-1 text-base leading-7 text-gray-700 sm:col-span-2 sm:mt-0"><span
                    class="roleBadge bg-role3 mr-2">formateur•rice métier</span><span
                    class="roleBadge bg-role4 mr-2">formateur•rice
                    référent</span><span class="roleBadge bg-role2">apprenant</span></dd>
              </div>
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-base font-medium leading-7 text-gray-900">Date</dt>
                <dd class="mt-1 flex text-base leading-7 text-gray-700 sm:col-span-2 sm:mt-0">
                  <div class="flex-grow">
                    <div class="flex w-full flex-none gap-x-2">
                      <div class="flex-none">
                        <span class="sr-only">Due date</span>
                        <CalendarDaysIcon class="h-6 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                      <div class="text-base leading-7 text-gray-500">
                        <time datetime="2023-01-31">12/09/2024 9:00</time>
                      </div>
                    </div>
                  </div>
                  <span class="ml-4 flex-shrink-0">
                    <button type="button"
                      class="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500">Modifier</button>
                  </span>
                </dd>

              </div>
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-base font-medium leading-7 text-gray-900">Participants</dt>
                <dd class="mt-1 flex text-base leading-7 text-gray-700 sm:col-span-2 sm:mt-0">
                  <span class="flex-grow">Backend Developer</span>
                  <span class="ml-4 flex-shrink-0">
                    <button type="button"
                      class="rounded-md bg-white font-medium text-indigo-600 hover:text-indigo-500">Ajouter</button>
                  </span>
                </dd>
              </div>
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-base font-medium leading- text-gray-900">Enjeu, objectif de cette étape</dt>
                <dd class="mt-1 text-base leading-7 text-gray-700 sm:col-span-2 sm:mt-0">Fugiat ipsum ipsum deserunt
                  culpa
                  aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur qui ipsum aliquip consequat
                  sint.
                  Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud pariatur mollit ad
                  adipisicing
                  reprehenderit deserunt qui eu.</dd>
              </div>
              <div class="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt class="text-base font-medium leading-7 text-gray-900">Attentes</dt>
                <dd class="mt-1 text-base leading-7 text-gray-700 sm:col-span-2 sm:mt-0">
                  <p><b>Pour le formateur métier</b>, gugiat ipsum ipsum deserunt
                    culpa
                    aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur qui ipsum aliquip consequat
                    sint.</p>
                  <p>
                    <b>Pour le formateur référent</b>, Sit id mollit nulla mollit nostrud in ea officia proident. Irure
                    nostrud pariatur mollit ad
                    adipisicing
                    reprehenderit deserunt qui eu
                  </p>
                  <p>
                    <b>Pour l'apprenant</b>, Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                    pariatur mollit ad
                    adipisicing
                    reprehenderit deserunt qui eu
                  </p>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div class="mb-32" id="before">
          <div class="px-4 sm:px-0">
            <h3 class="text-xl font-semibold leading-7  text-indigo-700"><span
                v-if="route.params.idMilestone === '3'">L'entretien réflexif amont</span><span v-else>Documents
                support</span></h3>
          </div>
          <div class="mt-6 border-t border-gray-100">
            <dl class="">

              <div class="px-4 py-6 sm:px-0 mx-auto max-w-[900px]">
                <dd class="mt-1 text-base leading-7 text-gray-700 sm:col-span-2 sm:mt-0"><span
                    v-if="route.params.idMilestone === '3'">L'enjeu de l'entretien réflexif amont est Fugiat ipsum ipsum
                    deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur qui ipsum
                    aliquip consequat sint. Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud
                    pariatur mollit ad adipisicing reprehenderit deserunt qui eu.</span><span v-else>Vous pouvez
                    utiliser les
                    documents suivants pour animer le rendez-vous, expliquer la Fest, le déroulé des situations de
                    travail...</span></dd>
              </div>
              <div class="px-4 py-6 sm:px-0 mx-auto max-w-[900px]">
                <dd class="mt-2 text-base text-gray-900 sm:col-span-2 sm:mt-0">
                  <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
                    <li class="flex items-center justify-between py-4 pl-4 pr-5 text-base leading-7"
                      v-for="file in files" :key="file.id">
                      <div class="flex w-0 flex-1 items-center">
                        <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <div class="ml-4 flex min-w-0 flex-1 gap-2">
                          <span class="truncate font-medium">{{ file.name }}</span>
                          <span class="flex-shrink-0 text-gray-500">{{ file.description }}</span>
                        </div>
                      </div>
                      <div class="ml-4 flex-shrink-0">
                        <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">Voir</a>
                      </div>
                    </li>

                  </ul>
                </dd>
              </div>
              <div class="bg-indigo-50 mt-10 shadow sm:rounded-lg">
                <div class="px-4 py-5 sm:p-6">
                  <div class="">
                    <div class="flex items-center gap-4">
                      <div>
                        <h3 class="text-base font-semibold leading-7  text-indigo-700"><span
                            v-if="route.params.idMilestone === '3'">Document à
                            rédiger</span><span v-if="route.params.idMilestone === '4'">Ce que l'on retient de ces
                            séances de FEST</span><span v-else>Ce que l'on retient de l'entretien</span></h3>
                        <div class="mt-2 text-base text-gray-500">
                          <p class="text-gray-900" v-if="route.params.idMilestone === '3'">Fugiat ipsum ipsum deserunt
                            culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur qui ipsum
                            aliquip consequat sint.</p>
                          <p v-else-if="$route.params.idMilestone === '4'">Fugiat ipsum ipsum deserunt
                            culpa aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur qui ipsum
                            aliquip consequat sint.</p>
                          <p v-else>Décisions prises, éléments à
                            retenir, synthèse des échanges, ...</p>
                        </div>
                      </div>
                      <div class="ml-auto"> <button type="button" class="btn-read whitespace-nowrap">Télécharger
                          le modèle</button></div>
                    </div>
                    <div class="rounded-md border border-gray-200 bg-white mt-5"
                      v-if="route.params.idMilestone === '3'">
                      <div class="flex items-center justify-between py-4 pl-4 pr-5 text-base leading-7">
                        <div class="flex w-0 flex-1 items-center">
                          <div class="mx-auto flex h-10 w-10 items-center justify-center rounded-full bg-green-100">
                            <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                          </div>
                          <div class="ml-4 flex min-w-0 flex-1 gap-2">
                            <span class="truncate font-medium">Entretien réflexif amont</span>
                            <span class="flex-shrink-0 text-gray-500">déposé le 14/09/2024</span>
                          </div>
                        </div>
                        <div class="ml-4 flex-shrink-0">
                          <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">Voir</a>
                        </div>
                      </div>
                    </div>

                    <div class="mt-10 sm:mt-5 sm:flex sm:flex-shrink-0 sm:items-center" v-else>
                      <button type="button"
                        class="mr-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Rédiger
                        en ligne</button>
                      <button type="button"
                        class="mr-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Déposer
                        un fichier</button>
                    </div>
                    <div v-if="route.params.idMilestone === '2'">
                      <h3 class="text-base font-semibold leading-7  text-indigo-700 mt-10 mb-5">Programmer les 4
                        situations
                      </h3>
                      <div class="flex">
                        <div class=""> <button type="button" class="btn-read flex-col">
                            <div class="text-indigo-600">Situation 1</div>
                            <div>13/09/2024 - 10h</div>
                          </button>
                        </div>
                        <div class=""> <button type="button" class="btn-read flex-col">
                            <div class="text-indigo-600">Situation 2</div>
                            <div>Date indéfinie</div>
                          </button>
                        </div>
                        <div class=""> <button type="button" class="btn-read flex-col">
                            <div class="text-indigo-600">Situation 3</div>
                            <div>Date indéfinie</div>
                          </button>
                        </div>
                        <div class=""> <button type="button" class="btn-read flex-col">
                            <div class="text-indigo-600">Situation 4</div>
                            <div>Date indéfinie</div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </dl>
          </div>
        </div>
        <div class="mb-32" id="after" v-if="route.params.idMilestone === '3'">
          <div class="px-4 sm:px-0">
            <h3 class="text-xl font-semibold leading-7  text-indigo-700"><span
                v-if="route.params.idMilestone === '3'">L'entretien réflexif aval</span><span v-else>Prise de
                note</span></h3>
          </div>
          <div class="mt-6 border-t border-gray-100">
            <dl class="">

              <div class="px-4 py-6 sm:px-0 mx-auto max-w-[900px]">
                <dd class="mt-1 text-base leading-7 text-gray-700 sm:col-span-2 sm:mt-0">Fugiat ipsum ipsum deserunt
                  culpa
                  aute sint do nostrud anim incididunt cillum culpa consequat. Excepteur qui ipsum aliquip consequat
                  sint.
                  Sit id mollit nulla mollit nostrud in ea officia proident. Irure nostrud pariatur mollit ad
                  adipisicing
                  reprehenderit deserunt qui eu.</dd>
              </div>

              <div class="px-4 py-6 sm:px-0 mx-auto max-w-[900px]">

                <dd class="mt-2 text-base text-gray-900 sm:col-span-2 sm:mt-0">
                  <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
                    <li class="flex items-center justify-between py-4 pl-4 pr-5 text-base leading-7"
                      v-for="file in filesAval" :key="file.id">
                      <div class="flex w-0 flex-1 items-center">
                        <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <div class="ml-4 flex min-w-0 flex-1 gap-2">
                          <span class="truncate font-medium">{{ file.name }}</span>
                          <span class="flex-shrink-0 text-gray-500">{{ file.description }}</span>
                        </div>
                      </div>
                      <div class="ml-4 flex-shrink-0">
                        <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500">Voir</a>
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>
              <div class="bg-indigo-50 mt-10 shadow sm:rounded-lg">
                <div class="px-4 py-5 sm:p-6">
                  <div>
                    <div class="flex items-center">
                      <div>
                        <h3 class="text-base font-semibold leading-7  text-indigo-700"><span>Document à rédiger</span>
                        </h3>
                        <div class="mt-2 text-base text-gray-500">
                          <p class="text-gray-900">Compte-rendu de l'échange, objectifs, situation(s) de travail
                            choisie(s), dates prévues.</p>
                        </div>
                      </div>
                      <div class="ml-auto"> <button type="button" class="btn-read">Télécharger
                          le modèle</button></div>
                    </div>
                    <div class="mt-10 sm:mt-5 sm:flex sm:flex-shrink-0 sm:items-center">
                      <button type="button"
                        class="mr-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Rédiger
                        en ligne</button>
                      <button type="button"
                        class="mr-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Déposer
                        un fichier</button>

                    </div>

                  </div>
                </div>
              </div>
            </dl>
          </div>

        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import AppLayout from '@/layouts/AppLayout.vue';
import client from '@/api/client';
import { UserCircleIcon } from '@heroicons/vue/24/solid';
import StructureCard from '@/components/structures/StructureCard.vue';
import { PaperClipIcon, EllipsisVerticalIcon, CalendarDaysIcon, CheckIcon } from '@heroicons/vue/20/solid';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import UploadFile from '@/components/files/UploadFile.vue';

const steps = [
  { id: 'Profils', name: 'Profils associés', href: '#profiles', status: 'upcoming', title: 'Informations générales sur la structure', description: 'Renseignez ici les informations générales de localisation et contact sur la structure' },
  { id: 'Structures', name: 'Structures associées', href: '#structures', status: 'upcoming', title: 'Présentation de la structure', description: 'Présentez ici de la structure, ses activités, ses publics, ses spécificités, etc.' },
]
const route = useRoute();
const relevantTrainingAction = ref([]);
const trainingActionId = ref(route.params.id);
const startDate = ref(null);
const endDate = ref(null);
let profilesRelated = ref([]);
const tabs = [
  { name: 'Présentation', href: '#intro', current: false },
  { name: 'Avant', href: '#before', current: false },
  { name: 'Après', href: '#after', current: false },
]

const files = ref([
  {
    name: 'Mener un entretien réflexif amont',
    description: 'Quelques conseils pour être à l\'aise',
    type: 'pdf',
  },
  {
    name: 'Vidéo d\'un entretien réflexif',
    description: 'Description du document 2',
    type: 'video',
  }
]);

const filesAval = ref([
  {
    name: 'Mener un entretien réflexif aval',
    description: 'Quelques conseils',
    type: 'pdf',
  },
  {
    name: 'Vidéo d\'un entretien réflexif',
    description: 'Description du document 2',
    type: 'video',
  }
]);
onMounted(async () => {

});

</script>
<style scoped>
b {
  @apply font-medium;
}

p {
  @apply mb-5;
}
</style>
