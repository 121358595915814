<template>
  <DocumentLayout>
    <template v-slot:title>{{ actionSelected?.degree?.name }}</template>
    <template v-slot:nav>
      <NavFiles :action="route.params.idAction" :key="componentKey" />
    </template>
    <template v-slot:main>
      <SmallLoader v-if="isLoading" :text="'Chargement en cours'" class="mb-10" />
      <FilesManager :folders="relevantFolders" :key="componentKey" @update="fetchFolders" v-if="relevantFolders" />
    </template>
  </DocumentLayout>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref, inject } from 'vue';
import DocumentLayout from '@/layouts/DocumentLayout.vue';
import FilesManager from '@/components/files/FilesManager.vue';
import client from '@/api/client';
import { useRoute, useRouter } from 'vue-router';
import NavFiles from '@/components/files/NavFiles.vue';
import SmallLoader from '@/components/app/SmallLoader.vue';

const route = useRoute();
const relevantFolders = ref([]);

const componentKey = ref(0);
const actionSelected = ref(null);
const isLoading = ref(true);

const fetchFolders = async () => {
  isLoading.value = true;
  try {
    const response = await client.get(`/api/training-actions/${route.params.idAction}/folders`);
    relevantFolders.value = response.data.folders;
    componentKey.value += 1;
    isLoading.value = false;
  } catch (error) {
    console.log(error);
  }
}

const fetchFoldersByUser = async () => {
  console.log('fetch folders by user');

  try {
    const response = await client.get(`/api/user/1/folders`);
    console.log(response.data);
  } catch (error) {
    console.log(error);
  }
}

onMounted(() => {
  fetchFolders();
});
</script>
