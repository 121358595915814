import { createRouter, createWebHistory } from 'vue-router';
import Cookies from 'js-cookie';
import store from '@/store/store';
import NextEvent from '@/views/events/NextEvent.vue';
import HomePage from '@/views/HomePage.vue';
import DashBoard from '@/views/dashboards/DashBoard.vue';
import ResetPassword from '@/views/auth/ResetPassword.vue';
import ResetPasswordConfirmation from '@/views/auth/ResetPasswordConfirmation.vue';
import StructuresList from '../views/structures/StructuresList.vue';
import StructureDetail from '../views/structures/StructureDetail.vue';
import UserDetail from '../views/users/UserDetail.vue';
import ProfileCreation from '../views/users/ProfileCreation.vue';
import ProfileModification from '../views/users/ProfileModification.vue';
import StructuresSelected from '../views/structures/StructuresSelected.vue';
import StructuresSelectedByStudent from '../views/structures/StructuresSelectedByStudent.vue';
import StructuresSelectedPostEvent from '../views/structures/StructuresSelectedPostEvent.vue';
import LoginScreen from '../views/auth/LoginScreen.vue';
import LegalInfos from '../views/legal/LegalInfos.vue';
import StructureCreation from '../views/structures/StructureCreation.vue';
import StructureModification from '../views/structures/StructureModification.vue';
import PageNotFound from '../views/errors/PageNotFound.vue';
import EventAdmin from '../views/events/EventAdmin.vue';
import TrainingActionDetail from '../views/actions/TrainingActionDetail.vue';
import EmailVerification from '../views/auth/EmailVerification.vue';
import FilesManager from '../views/files/FilesManager.vue';
import FolderManager from '../views/files/FolderManager.vue';
import FileManager from '../views/files/FileManager.vue';
import ActionFilesManager from '../views/files/ActionFilesManager.vue';
import MilestoneDetail from '../views/milestones/MilestoneDetail.vue';

const routes = [
  {
    path: '/',
    name: 'Homepage',
    component: HomePage,
    meta: {
      title: 'Accueil - Trajectoire FEST',
      requiresAuth: false,
      layout: 'AppLayout',
      allowedRoles: [],
    },
  },
  {
    path: '/tableau-de-bord',
    name: 'Tableau de bord',
    component: DashBoard,
    meta: {
      title: 'Tableau de bord - Trajectoire FEST',
      requiresAuth: true,
      layout: 'AppLayout',
      allowedRoles: [],
    },
  },
  {
    path: '/authentification',
    name: 'Authentification',
    component: LoginScreen,
    meta: {
      title: 'Authentification - Trajectoire FEST',
      requiresAuth: false,
    },
  },
  {
    path: '/verification-email',
    name: 'EmailVerification',
    component: EmailVerification,
    meta: {
      title: 'Vérification de l\'adresse email - Trajectoire FEST',
      requiresAuth: false,
    },
  },
  {
    path: '/structures',
    name: 'Catalogue de structures',
    component: StructuresList,
    meta: {
      title: 'Catalogue de structures - Trajectoire FEST',
      requiresAuth: false,
      layout: 'AppLayout',
    },
  },
  {
    path: '/structures/:id',
    name: 'Détail d\'une structure',
    component: StructureDetail,
    meta: {
      title: 'Détail d\'une structure - Trajectoire FEST',
      requiresAuth: false,
      layout: 'AppLayout',
      allowedRoles: [],
    },
  },
  {
    path: '/structures/formateur/:id',
    name: 'Détail d\'une personne',
    component: UserDetail,
    meta: {
      title: 'Détail d\'un formateur - Trajectoire FEST',
      requiresAuth: false,
      allowedRoles: [],
    },
  },
  {
    path: '/structures/creer-ma-structure',
    name: 'Créer ma structure',
    component: StructureCreation,
    meta: {
      title: 'Créer une structure - Trajectoire FEST',
      requiresAuth: true,
      allowedRoles: ['Formateur·trice référent·e', 'Formateur·trice métier', 'Coordinateur·trice', 'Assitant·e de formation', 'Administrateur·trice'],
    },
  },
  {
    path: '/structures/modifier-ma-structure/:id',
    name: 'Modifier une structure',
    component: StructureModification,
    meta: {
      title: 'Modifier une structure - Trajectoire FEST',
      requiresAuth: true,
      allowedRoles: ['Formateur·trice référent·e', 'Formateur·trice métier', 'Coordinateur·trice', 'Assitant·e de formation', 'Administrateur·trice'],
    },
  },
  {
    path: '/utilisateurs/profil/:id',
    name: 'Détails de l\'utilisateur',
    component: UserDetail,
    meta: {
      title: 'Détails du profil - Trajectoire FEST',
      layout: 'AppLayout',
      requiresAuth: false,
      allowedRoles: [],
    },
  },
  {
    path: '/profil/structures-selectionnees/:eventId',
    name: 'Détails des structures sélectionnées',
    component: StructuresSelected,
    meta: {
      title: 'Détails des structures sélectionnées - Trajectoire FEST',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: [],
    },
  },
  {
    path: '/profil/structures-candidature/:eventId',
    name: 'Choix des structures pour mon alternance',
    component: StructuresSelectedPostEvent,
    meta: {
      title: 'Détails des structures sélectionnées pour réaliser mon aternance - Trajectoire FEST',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: [],
    },
  },
  {
    path: '/apprenant-voeux/:id/:eventId',
    name: 'Détails des voeux d\'un apprenant',
    component: StructuresSelectedByStudent,
    meta: {
      title: 'Détails des voeux d\'un apprenant - Trajectoire FEST',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['Coordinateur·trice', 'Assitant·e de formation', 'Administrateur·trice'],
    },
  },
  {
    path: '/utilisateurs/profil/creer-mon-profil',
    name: 'Créer un profil',
    component: ProfileCreation,
    meta: {
      title: 'Modification utilisateur - Trajectoire FEST',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: [],
    },
  },
  {
    path: '/utilisateurs/profil/modifier-mon-profil/:id',
    name: 'Modifier un profil',
    component: ProfileModification,
    meta: {
      title: 'Modification utilisateur - Trajectoire FEST',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: [],
    },
  },
  {
    path: '/evenements/edition/:id',
    name: 'Modifier un événement',
    component: EventAdmin,
    meta: {
      title: 'Modification d\'un événement - Trajectoire FEST',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['Coordinateur·trice', 'Assitant·e de formation', 'Administrateur·trice'],
    },
  },
  {
    path: '/actions-formation/:id',
    name: 'Voir une action de formation',
    component: TrainingActionDetail,
    meta: {
      title: 'Action de formation - Trajectoire FEST',
      layout: 'AppLayout',
      requiresAuth: true,
      allowedRoles: ['Coordinateur·trice', 'Assitant·e de formation', 'Administrateur·trice'],
    },
  },
  {
    path: '/informations-legales',
    name: 'Informations légales',
    component: LegalInfos,
    meta: {
      title: 'Informations légales - Trajectoire fest',
      layout: 'AppLayout',
      requiresAuth: false,
    },
  },
  {
    path: '/prochain-evenement',
    name: 'Prochain événement',
    component: NextEvent,
    meta: {
      title: 'Prochain événement - Trajectoire fest',
      layout: 'AppLayout',
      requiresAuth: false,
    },
  },
  {
    path: '/documents',
    name: 'Accéder à mes documents',
    component: FilesManager,
    meta: {
      title: 'Mes documents - Trajectoire FEST',
      requiresAuth: true,
      allowedRoles: ['Formateur·trice référent·e', 'Formateur·trice métier', 'Coordinateur·trice', 'Assitant·e de formation', 'Administrateur·trice', 'Apprenant·e'],
    },
  },
  {
    path: '/documents/:idDocument',
    name: 'Accéder à un document',
    component: FileManager,
    meta: {
      title: 'Mon document- Trajectoire FEST',
      requiresAuth: true,
      allowedRoles: ['Formateur·trice référent·e', 'Formateur·trice métier', 'Coordinateur·trice', 'Assitant·e de formation', 'Administrateur·trice', 'Apprenant·e'],
    },
  },

  {
    path: '/documents/dossier/:idFolder',
    name: 'Accéder à un dossier',
    component: FolderManager,
    meta: {
      title: 'Mon document- Trajectoire FEST',
      requiresAuth: true,
      allowedRoles: ['Formateur·trice référent·e', 'Formateur·trice métier', 'Coordinateur·trice', 'Assitant·e de formation', 'Administrateur·trice', 'Apprenant·e'],
    },
  },
  {
    path: '/documents/action/:idAction',
    name: 'Accéder à aux dossiers d\'une action',
    component: ActionFilesManager,
    meta: {
      title: 'Dossiers - Trajectoire FEST',
      requiresAuth: true,
      allowedRoles: ['Formateur·trice référent·e', 'Formateur·trice métier', 'Coordinateur·trice', 'Assitant·e de formation', 'Administrateur·trice', 'Stagiaire', 'Apprenant·e'],
    },
  },
  {
    path: '/etape/:idMilestone',
    name: 'Accéder au détail d\'une étape de Fest',
    component: MilestoneDetail,
    meta: {
      title: 'Dossiers - Trajectoire FEST',
      requiresAuth: true,
      allowedRoles: ['Formateur·trice référent·e', 'Formateur·trice métier', 'Coordinateur·trice', 'Assitant·e de formation', 'Administrateur·trice', 'Stagiaire', 'Apprenant·e'],
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: PageNotFound,
    meta: {
      title: 'Oups... - SIRT',
      requiresAuth: false,
    },
  },
  {
    path: '/reinitialisation',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      title: 'Réinitialisation - Trajectoire fest',
    },
  },
  {
    path: '/reinitialisation-mot-de-passe',
    name: 'ResetPasswordConfirmation',
    component: ResetPasswordConfirmation,
    meta: {
      title: 'Confirmation - Trajectoire fest',
    },
  },
  {
    path: '/verification-email',
    name: 'EmailVerification',
    component: EmailVerification,
    meta: {
      title: 'Confirmation - Trajectoire fest',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title || 'Trajectoire FEST'; // Set the document title
  console.log(`Navigating to: ${to.name}`);

  const token = Cookies.get('token');

  // Load user profiles and chosen profile from local storage if they exist
  if (!store.userProfiles.length) {
    store.loadUserProfiles();
  }
  if (!store.chosenProfile) {
    store.loadChosenProfile();
  }

  // Always try to fetch user profiles if a token is present and profiles are not already stored
  if (token && store.userProfiles.length === 0) {
    try {
      await store.fetchUserProfiles(); // Ensure fetchUserProfiles is awaited
    } catch (error) {
      console.error('Error fetching user profiles:', error);
      Cookies.remove('token');
      localStorage.removeItem('userProfiles');
      return next({ name: 'Authentification' });
    }
  }

  // Check if the route requires authentication
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!token) {
      console.log('No token found, redirecting to Authentification');
      return next({ name: 'Authentification' });
    }
  }

  // Check if user profiles are present before accessing the dashboard
  if (to.name === 'Tableau de bord' && store.userProfiles.length === 0) {
    return next({ name: 'Créer un profil' });
  }

  // Check if the route requires specific roles and allowedRoles is not empty
  if (to.meta.allowedRoles && to.meta.allowedRoles.length > 0 && store.userProfiles.length > 0 && store.chosenProfile) {
    const userRole = store.chosenProfile.role.name.toLowerCase(); // Use the chosen profile's role
    const allowedRoles = to.meta.allowedRoles.map((role) => role.toLowerCase());
    console.log(`Allowed roles: ${allowedRoles}`);
    if (!allowedRoles.includes(userRole)) {
      console.log('Role not allowed, redirecting to Tableau de bord');
      return next({ name: 'Tableau de bord' });
    }
  }

  return next();
});

router.afterEach((to) => {
  document.title = to.meta.title || 'Trajectoire FEST'; // Fallback title if none is specified
});

export default router;
