<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <ConfirmationDialog @confirm="removeFolder(idTypeToEdit)" v-if="displayConfirmDeleteDialog"
    title="Supprimer ce dossier" message="Voulez-vous vraiement supprimer ce dossier ?" primaryActionLabel="Supprimer"
    secondaryActionLabel="Annuler" @close="displayConfirmDeleteDialog = false" />
  <AddFolder v-if="displayEditFolder" @close="displayEditFolder = false" @confirm="saveFolder" :type="typeSelected"
    :folder="folderSelected" :key="componentKey" />

  <div class="bg-white" style="min-height: calc( 100vh - 400px );">
    <div class="w-full">
      <div class="w-full grid 2xl:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-6">
        <div class="card shadow" v-for="folder in relevantFolders" :key="folder.id">
          <div class="p-5">
            <div class="space-y-4 text-gray-600 dark:text-gray-300">
              <div class="flex items-start relative gap-5">

                <router-link :to="'/documents/action/' + folder.id"><button class="flex items-center gap-3 text-left">
                    <div class="h-14 w-14">
                      <span class="flex h-full w-full items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1"
                          stroke="currentColor" class="size-20 stroke-indigo-500">
                          <path stroke-linecap="round" stroke-linejoin="round"
                            d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
                        </svg>

                      </span>
                    </div>
                    <div class="space-y-1">
                      <p class="font-semibold text-base">{{ folder.degree.name }} - {{ folder.year }}</p>
                    </div>
                  </button></router-link>

              </div>

            </div>
          </div> <!-- end card body -->
        </div>
      </div>
    </div>

  </div>

</template>

<script setup>
/* eslint-disable */
import { onMounted, ref, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AddFolder from '@/components/files/AddFolder.vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';

const route = useRoute();
const router = useRouter();
const displayEditFolder = ref(false);
let typeSelected = ref(null);
let componentKey = ref(0);
let folderSelected = ref(null);
const idTypeToEdit = ref(null);

const displayConfirmDeleteDialog = ref(false);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');

const props = defineProps({
  profile: [Object, Array],
  folders: [Object, Array],
});
const emit = defineEmits(['update', 'changePage']);

const statuses = {
  Complete: 'text-green-700 bg-green-50 ring-green-600/20',
  'In progress': 'text-gray-600 bg-gray-50 ring-gray-500/10',
  Archived: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}
const relevantFolders = ref([]);

const gotoFolderDetail = (id) => {
  console.log('goto folder detail');
  const dossier = id;
  router.push({ name: 'Accéder à un dossier', params: { idFolder: id } });
  emit('changePage', 'folder');
}


onMounted(() => {
  relevantFolders.value = props.folders;
  console.log('relevantFolders', relevantFolders.value);
});
</script>
