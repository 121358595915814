<template>
  <AppLayout>
    <CreateAccountWarning v-if="displaySuccesDialog" title="Tu dois créer un compte pour enregistrer tes vœux"
      message="Ne perds pas ta sélection et inscris-toi maintenant !" primaryButtonLabel="Créer un compte"
      secondaryButtonLabel="" @closeDialog="displaySuccesDialog = false" />
    <div class="mx-auto 2xl:max-w-9xl detail-page" v-if="chosenStructure">
      <div class="hero-structure mt-20">
        <div class="md:flex justify-center">
          <StructureCard :displayedStructure="chosenStructure" :typeCard="'page-detail'" />
          <div style="max-height:500px" class="columns-2 md:w-2/3 gap-4 md:pl-4 mt-4 md:mt-0 md:mb-0 mb-4">
            <img v-if="chosenStructure.photos && chosenStructure.photos[0]" :src="chosenStructure?.photos[0]?.url"
              alt="" width="300" height="200" class="img1 object-cover rounded-lg w-full h-full">
            <div v-else class="img1 object-cover rounded-lg w-full h-full bg-gray-100 flex">
              <PhotoIcon class="h-8 w-8 text-gray-300 m-auto" />
            </div>
            <img v-if="chosenStructure.photos && chosenStructure.photos.length > 1"
              :src="chosenStructure?.photos[1]?.url" alt="" class="img2 object-cover rounded-xl w-full img-small">
            <div v-else class="img2 object-cover rounded-lg w-full img-small bg-gray-100 flex">
              <PhotoIcon class="h-8 w-8 text-gray-300 m-auto" />
            </div>
            <div class="h-4"></div>
            <img v-if="chosenStructure.photos && chosenStructure.photos.length > 2"
              :src="chosenStructure?.photos[2]?.url" alt="" class="img2 object-cover rounded-lg w-full img-small">
            <div v-else class="img2 object-cover rounded-lg w-full img-small bg-gray-100 flex">
              <PhotoIcon class="h-8 w-8 text-gray-300 m-auto" />
            </div>
          </div>
        </div>
      </div>
      <div class="container-page container-large bg-white rounded-xl px-20">
        <div class="container-page">
          <section class="pt-20">
            <p v-if="chosenStructure.description">{{ chosenStructure.description }}</p>
          </section>
          <section id="video" class="mx-auto py-0 my-8" v-if="chosenStructure.video">
            <iframe width="1204" height="677" class="w-full mx-auto relative z-1"
              src="https://www.youtube.com/embed/GElvZn62huE"
              title="Point d'étape sur la recherche-action FEST de Trajectoire Formation" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen=""></iframe>
          </section>
          <section class="twocols">
            <div v-if="chosenStructure.current_project">
              <h2 class="title2">Projet du moment, actualité de la structure</h2>
              <p>{{ chosenStructure.current_project }}</p>
            </div>
            <div class="lg:mt-32" v-if="chosenStructure.structure_approach">
              <h2 class="title2">Ce qui m’intéresse dans le fait d’être une structure apprenante</h2>
              <p>{{ chosenStructure.structure_approach }}</p>
            </div>
          </section>
          <section v-if="chosenStructure.enrolment_speech">
            <h2 class="title2">Si vous venez chez moi, il faut que vous sachiez que… </h2>
            <p> {{ chosenStructure.enrolment_speech }}</p>
          </section>
          <section v-if="chosenStructure.training_situations" class="mt-10">

            <div v-for="trainingSituation in  chosenStructure.training_situations" :key="trainingSituation.id"
              class="mb-5 card-situation lg:flex items-stretch situation-card rounded-3xl border border-gray-200 bg-white shadow-lg shadow-gray-500/50">
              <span v-if="trainingSituation.situation_photo"> </span>
              <div
                class="img-situation bg-center bg-cover before:!bg-indigo-900 text-white text-center xl:w-1/3 rounded-3xl flex flex-col px-6 py-16"
                :style="'background-image: linear-gradient(155deg, rgba(88,30,255,0.81) 0%, rgba(4,0,36,0.53) 100%),url(' + trainingSituation.situation_photo_url + ')'">
                <p class="card-subtitle mt-2 md:mt-10 text-lg">Situation de travail emblématique</p>

                <p class="card-title mt-auto !mb-auto text-2xl leading-10 py-4 ">{{ trainingSituation.name }}</p>

              </div>
              <div class="p-10 w-full flex items-center">
                <p class="w-full">{{ trainingSituation.description }}</p>
              </div>
            </div>
          </section>
          <section class="mt-10" v-for="( profiles, category ) in   chosenStructure.attachedProfiles  " :key="profiles">
            <div v-if="category.includes('m\u00e9tier')">
              <div v-for="  teacher   in   profiles  " :key="teacher.id" class="flex items-center mb-8 md:mb-20">
                <router-link :to="'/utilisateurs/profil/' + teacher.id" class="flex items-center mx-auto">
                  <div>
                    <div class="img-team-container">
                      <UserCircleIcon class="h-12 w-12 text-gray-300 m-auto" aria-hidden="true"
                        v-if="!teacher.profile_picture" />
                      <div v-else>
                        <img class="img-team" :src="teacher.profile_picture_url" alt="" />
                      </div>
                    </div>
                  </div>
                  <div class="ml-4">
                    <p class="text-lg md:text-2xl lg:text-3xl">{{ teacher.name }}</p>
                    <p class="uppercase text-indigo-900 !tracking-widest text-md md:text-xl lg:text-xl">{{ category }}
                    </p>
                  </div>
                </router-link>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="flex items-center p-4 sm:p-8 border-t border-b border-solid border-black mt-8" v-if="!currentProfile"
        @keyup.enter="toggleSelectStructure(chosenStructure);" @click.enter="toggleSelectStructure(chosenStructure);">
        <div class="flex items-center m-auto">
          <StarIcon class="h-8 w-8" :class="isSelected ? 'fill-black' : ''" />
          <p class=" uppercase !tracking-widest !mb-0 pt-1 ml-4 text-lg sm:text-2xl lg:text-3xl">Sélectionner</p>
        </div>
      </div>
      <div v-else>
        <div class="flex items-center p-4 sm:p-8 border-t border-b border-solid border-black mt-8"
          v-if="currentProfile.role.name === 'Apprenant·e'" @keyup.enter="toggleSelectStructure(chosenStructure)"
          @click.enter="toggleSelectStructure(chosenStructure)">
          <div class="flex items-center m-auto">
            <StarIcon class="h-8 w-8" :class="isSelected ? 'fill-black' : ''" />
            <p class=" uppercase !tracking-widest !mb-0 pt-1 ml-4 text-lg sm:text-2xl lg:text-3xl">Sélectionner</p>
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script setup>
/* eslint-disable */
import { ref } from 'vue';
import { onMounted, computed, inject } from 'vue';
import { useRoute } from 'vue-router';
import AppLayout from '@/layouts/AppLayout.vue';
import StructureCard from '@/components/structures/StructureCard.vue';
import { StarIcon, PhotoIcon, UserCircleIcon } from '@heroicons/vue/24/outline';
import client from '@/api/client';
import CreateAccountWarning from '@/components/dialogs/CreateAccountWarning.vue';

const chosenStructure = ref({});
const store = inject('store');
const route = useRoute();
const structureId = route.params.id;
const currentProfile = store.chosenProfile;
const displaySuccesDialog = ref(false);

const isSelected = computed(() => {
  return store.selectedStructures.some(structure => structure.id === chosenStructure.value.id);
});

const toggleSelectStructure = (structure) => {
  if (isSelected.value) {
    store.removeStructure(structure.id);
    displaySuccesDialog.value = false;
  } else {
    store.addStructure(structure);
    if (!currentProfile) {
      displaySuccesDialog.value = true;
    }
  }
};

const fetchStructureDetails = async (id) => {
  try {
    const response = await client.get(`/api/structures/${id}`);
    chosenStructure.value = response.data;
  } catch (error) {
    console.error('Failed to fetch trainee details:', error);
  }
};

onMounted(() => {
  const { selectedStructures } = store;
  if (!selectedStructures.length) {
    const storedStructures = JSON.parse(localStorage.getItem('selectedStructures'));
    if (storedStructures) {
      storedStructures.forEach((structure) => {
        store.addStructure(structure);
      });
    }
  }
  if (structureId) {
    fetchStructureDetails(structureId);
  }
});
</script>
