<template>
    <button @click="open = true"
        class="flex items-center justify-center rounded-full px-1 py-1 text-sm  font-medium text-gray-900 bg-gray-200 hover:bg-gray-50">
        <ArrowPathIcon class="h-3 w-3" />
    </button>
    <TransitionRoot as="template" :show="open">
        <Dialog class="relative z-10" @close="open = false">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                            <div>

                                <div class="profile-switch">
                                    <label for="profile-select"
                                        class="block text-sm font-medium leading-6 text-gray-900">Selectionne
                                        ton
                                        profil</label>
                                    <select id="profile-select" v-model="selectedProfileId" @change="switchProfile"
                                        class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">>
                                        <option v-for="profile in profiles" :key="profile.id" :value="profile.id">
                                            {{ profile.role.name }} <span v-if="profile.structures.length > 0">-
                                                (<span v-for="(attachedStructure, index) in profile.structures"
                                                    :key="attachedStructure.id"> {{
        attachedStructure.name
    }}<span v-if="index < profile.structures.length - 1">,
                                                        &NonBreakingSpace;</span><span v-else> )</span>
                                                </span></span><span v-else>(Aucune structure attachée)</span>
                                        </option>
                                    </select>
                                </div>
                            </div>

                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>

        </Dialog>
    </TransitionRoot>

</template>

<script setup>
/* eslint-disable */
import { ref, watch, onMounted } from 'vue';
import store from '@/store/store';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { ArrowPathIcon } from '@heroicons/vue/24/solid';

let open = ref(false);

const selectedProfileId = ref(store.chosenProfile ? store.chosenProfile.id : null);
const profiles = ref(store.userProfiles);
const emit = defineEmits(['updateProfile', 'close']);

const switchProfile = () => {
    const chosenProfile = profiles.value.find(profile => profile.id === selectedProfileId.value);
    if (chosenProfile) {
        store.setChosenProfile(chosenProfile);
        emit('updateProfile');
        emit('close');
        open.value = false;
    }
};

onMounted(() => {
    if (!selectedProfileId.value && profiles.value.length > 0) {
        selectedProfileId.value = profiles.value[0].id;
    }
});

watch(() => store.userProfiles, (newProfiles) => {
    profiles.value = newProfiles;
    if (!selectedProfileId.value && newProfiles.length > 0) {
        selectedProfileId.value = newProfiles[0].id;
    }
});

watch(() => store.chosenProfile, (newProfile) => {
    if (newProfile) {
        selectedProfileId.value = newProfile.id;
    }
});
</script>

<style scoped>
/* Add any scoped styles here if needed */
</style>